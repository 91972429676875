import {
  faCheckCircle,
  faClipboard,
  faStopCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Card, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { db } from "../../../config/firebase";

export default function KeyList({ toggleStatus, deleteApiKey }) {
  const [keyList, setKeyList] = useState([]);
  const [showKey, setShowKey] = useState(false);

  useEffect(() => {
    // Load up API Keys, and set listener
    const removeListener = db.collection("BI_API_KEYS").onSnapshot((docs) => {
      if (docs.empty) {
        setKeyList([]);
        return;
      }
      const list = [...keyList];
      docs.forEach((doc) => {
        list.push(doc.data());
      });
      setKeyList(list);

      return function cleanup() {
        console.log("Remove Firestore Listener");
        removeListener();
      };
    });
  }, []);

  return (
    <>
      <Card bg="dark">
        <Card.Header>
          <div>
            <div className="text-center h3">API Users</div>
          </div>
        </Card.Header>
        <Card.Body>
          <Table
            responsive
            striped
            hover
            variant="dark"
            size="sm"
            className="text-center api-keys-table"
          >
            <thead>
              <tr>
                <th>Key</th>
                <th>Email</th>
                <th>Status</th>
                <th className="text-center">Remove</th>
              </tr>
            </thead>
            <tbody>
              {keyList.length > 0 &&
                keyList.map((key, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td>
                          <OverlayTrigger
                            key={index}
                            delay={250}
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>{key.key}</Tooltip>
                            }
                          >
                            <FontAwesomeIcon icon={faClipboard} className="" />
                          </OverlayTrigger>
                        </td>
                        <td>{key.email}</td>
                        <td className="">
                          {key.valid === true ? (
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="text-success api-table-status"
                              onClick={() => toggleStatus(key.key)}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faStopCircle}
                              className="text-warning  api-table-status"
                              onClick={() => toggleStatus(key.key)}
                            />
                          )}
                        </td>
                        <td className="text-center">
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="text-secondary api-table-status"
                            onClick={() => deleteApiKey(key.key)}
                          />
                        </td>
                      </tr>
                      {showKey && (
                        <tr>
                          <td>{key.key}</td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );

  return <div>Loading</div>;
}
