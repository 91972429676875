import React from 'react';
import { Table } from 'react-bootstrap';


export default function UsersTable({ keys, users, userDetailsToParent }) {
    const headings = {
        uid: 'uid',
        email: 'Email',
        displayName: 'Display Name',
        phoneNumber: 'Mobile Number',
        // lastActiveTime: 'Last Seen',
        // lastSignIn: 'Last Login',
        // createdAt: 'User Created',
        admin: 'Admin',
        general: 'GEN',
        flightOps: 'FltOps',
        opsMgmt: 'OpsMgmt',
        maintenance: 'Maint'

    };

    const handleClick = (user) => {
        userDetailsToParent(user.uid)

    }
    keys = keys.filter(k => k !== 'uid' && k!=='general' && k!=='flightOps' && k!=='opsMgmt' && k!=='maintenance')
    return (
        
            <Table 
                size="lg"
                hover
                borderless
                striped
                resonsive="xl"
                className=""
                id="users"
                
                
            >
                <thead className="text-light bg-dark">
                    <tr>
                        {keys.map((item, index) =>
                            
                                <th key={index}>{headings[item]}</th>
                            
                        )}
                        
                        
                    </tr>
                </thead>
                <tbody className="text-light bg-dark">
                    {users.map((user, index) => (
                        <tr 
                        key={index}
                        onClick={() => handleClick(user)}
                        >
                            {keys.map((item, i) =>
                               
                                    <td key={i}>
                                        {item ==='admin' && user[item]===true ? 'x' : ''}
                                        {item ==='general' && user[item]===true ? 'x' : ''}
                                        {item ==='flightOps' && user[item]===true ? 'x' : ''}
                                        {item ==='opsMgmt' && user[item]===true ? 'x' : ''}
                                        {item ==='maintenance' && user[item]===true ? 'x' : ''}
                                        {item !=='admin' && user[item]}
                                        
                                    </td>
                                
                            )}

                        </tr>
                    ))}
                </tbody>
            </Table>
        
    );
}
