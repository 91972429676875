import React, { useRef, useEffect, useState } from 'react';
import { Alert, Image, Container, Form, Button, Card } from 'react-bootstrap';
import { useAuth } from '../Contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';

export const Login = () => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const captchaRef = useRef(null)
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            setError('');
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value);
            history.push('/');
        } catch (err) {
            setError(`Sign in failed. ${err.message}`);
        }
        setLoading(false);
    };


    return (
        <>
            <div ref={captchaRef}></div>

            <Container
                className="mt-5 align-items-center justify-content-center"
                style={{ maxWidth: '500px' }}
            >
                <Card className="bg-dark">
                    <Card.Body>
                        <Image
                            className="pl-2 pr-5"
                            fluid
                            src="images/mfcLogo_white.png"
                        />
                        <div className="display-4 mb-4 text-center">
                            Airboss
                            
                        </div>
                        
                        {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        ref={emailRef}
                                        required
                                        autoComplete={'username'}
                                    />
                                </Form.Group>
                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        ref={passwordRef}
                                        required
                                        autoComplete={'current-password'}
                                    />
                                </Form.Group>

                                <Button
                                    disabled={loading}
                                    className="mt-2 pt-3 pb-3 w-100"
                                    type="submit"
                                >
                                    Log In
                            </Button>
                            </Form>
                    </Card.Body>
                </Card>
            </Container>
                <div className="w-100 text-center mt-3">
                    <Link to="/reset-password"> Forgot Password?</Link>
                </div>
                <div className="w-100 text-center mt-2">
                    Need an Account? Contact an Airboss admin.
                </div>
        </>
    );
};
