import React, { useState, useEffect } from 'react'
// import { auth } from '../../config/firebase';

import { Modal, Card, Button, Form, Alert, Row, Col } from 'react-bootstrap'
import { updateCador, deleteCador } from '../../api/cadorAPI'
import moment from 'moment-timezone';
import map from 'lodash/map';

import { cadorOccurenceCategory, tsbClassOfInvestigation, phaseOfFlight, damage, aircraftEventInfo, occurrenceEventInformation, navErrors } from '../../config/cadorFormData'




export const EditCadorModal = ({ editCadorModalOpts, closeEditCadorModal, aircraftInfo, cadorData }) => {
    const [loading, setLoading] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [saved, setSaved] = useState(true)
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [cador, setCador] = useState(cadorData)



    useEffect(() => {
        setCador(cadorData)
    }, [cadorData])

    const handleCancel = () => {
        //closeEditCadorModal();
        setError(null)
        setSaved(true)
        setDeleteConfirm(false)


    }

    const handleClose = () => {
        closeEditCadorModal();
        setSuccess(null)
        setError(null)
        setDeleteConfirm(false)

    }

    const handleUpdateCador = async () => {

        setLoading(true);
        try {
            let res = await updateCador(cador)
            if (res.isAxiosError) {

                let message = res.response.data.message

                throw new Error(message)
            }
            setSaved(true)
            setError(false)
            setSuccess('Cador Saved Successfully')



        } catch (error) {
            setError(error.message)
            console.log('Error', error)
        }
        setLoading(false);
    }

    const handleFormChange = (e) => {

        const fd = { ...cador }
        fd[e.target.id] = e.target.value;
        setCador(fd)
        setSaved(null)
    }
    
    const selectedOptionsChange = (e) => {
        const fd = {...cador}
        fd[e.target.id] = map(e.target.selectedOptions, option => option.value)
        setCador(fd)
        setSaved(null)

        
    }
    const handleDeleteCador = async () => {

        const cadorNumber = cador.cadorNumber

        const response = await deleteCador(cadorNumber);

        // console.log('Deleted Cador Result: ', response)
        setDeleteConfirm(false)
        closeEditCadorModal()
    }
    return (
        <div>


            <Modal

                size=''
                dialogClassName="modalStyle"
                animation={true}
                show={editCadorModalOpts.show}
                onHide={() => closeEditCadorModal()}
                align-items-center="true"
                justify-content-center="true"
                className='mt-3 mb-3'
                backdrop='static'

            >
                {cador &&
                    <Card className="bg-dark">
                        <Card.Header>
                            <Card.Title>Edit CADOR Details</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                {/********* Cador Summary ********/}
                                <Row>
                                    <Col className="mb-3 h4 text-danger" sm={12}>
                                        Cador Summary
                                            </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col sm={12} md={3}>
                                        <Form.Group controlId="cadorNumber">
                                            <Form.Label>CADOR Number</Form.Label>
                                            <Form.Control type="text" value={cador.cadorNumber} readOnly={true} onChange={(e) => handleFormChange(e)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={3}>
                                        <Form.Group controlId="smsId">
                                            <Form.Label>SMS ID</Form.Label>
                                            <Form.Control type="text" value={cador.smsId} onChange={(e) => handleFormChange(e)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={3}>
                                        <Form.Group controlId="TALON_ACT_ID">
                                            <Form.Label>TALON ID</Form.Label>
                                            <Form.Control type="text" value={cador.TALON_ACT_ID} onChange={(e) => handleFormChange(e)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={3}>
                                        <Form.Group controlId="occurrenceCategory">
                                            <Form.Label>Occurrence Category</Form.Label>
                                            <Form.Control as='select' multiple value={cador.occurrenceCategory} onChange={selectedOptionsChange}>
                                                {cadorOccurenceCategory.map((e, i) => {
                                                    return <option key={i} value={e}>{e}</option>
                                                })}
                                            </Form.Control>
                                            <Form.Text className="text-muted">Hold CTRL key and click to select multiple items, or to remove a selection, hold CTRL and click on the item to remvoe. </Form.Text>

                                        </Form.Group>

                                    </Col>

                                </Row>

                                {/******** Occurrence Information ********/}
                                <Row>
                                    <Col className="mb-3 h4 text-danger" sm={12}>Occurrence Information</Col>
                                    <Col sm={12} md={3}>
                                        <Form.Group controlId="occurrenceType">
                                            <Form.Label>OccurrenceType</Form.Label>
                                            <Form.Control as='select' value={cador.occurrenceType} placeholder='' onChange={(e) => handleFormChange(e)}>
                                                <option value="Incident">Incident</option>
                                                <option value="Accident">Accident</option>
                                            </Form.Control>
                                        </Form.Group>

                                    </Col>
                                    <Col sm={12} md={3}>
                                        <Form.Group controlId="occurrenceDate">
                                            <Form.Label>Occurrence Date</Form.Label>
                                            <Form.Control type='date' value={cador.occurrenceDate} onChange={(e) => handleFormChange(e)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={3}>
                                        <Form.Group controlId="occurrenceTime">
                                            <Form.Label>Occurrence Time (Z)</Form.Label>
                                            <Form.Control type='time' value={cador.occurrenceTime} onChange={(e) => handleFormChange(e)}></Form.Control>
                                        </Form.Group>

                                    </Col>
                                    <Col sm={12} md={3}>
                                        <Form.Group controlId="dayOrNight">
                                            <Form.Label>Day or Night:</Form.Label>
                                            <Form.Control as='select' placeholder='' value={cador.dayOrNight} onChange={(e) => handleFormChange(e)}>
                                                <option value="Day">Day</option>
                                                <option value="Night">Night</option>
                                                <option value="Not Specified">Not Specified</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={4}>
                                        <Form.Group controlId="aerodromeID">
                                            <Form.Label>Aerodrome ID</Form.Label>
                                            <Form.Control type='text' placeholder='' value={cador.aerodromeID} onChange={(e) => handleFormChange(e)}></Form.Control>
                                        </Form.Group>

                                    </Col>

                                    <Col sm={12} md={4}>
                                        <Form.Group controlId="occurrenceLocation">
                                            <Form.Label>Occurrence Location</Form.Label>
                                            <Form.Control type='text' value={cador.occurrenceLocation} onChange={(e) => handleFormChange(e)}></Form.Control>
                                        </Form.Group>

                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group controlId="reportedBy">
                                            <Form.Label>Reported By</Form.Label>
                                            <Form.Control type='text' value={cador.reportedBy} onChange={(e) => handleFormChange(e)}></Form.Control>
                                        </Form.Group>

                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm={12} md={4}>
                                        <Form.Group controlId="tsbClassOfInvestigation">
                                            <Form.Label>TSB Class of Investigation</Form.Label>
                                            <Form.Control as='select' placeholder='' value={cador.tsbClassOfInvestigation} onChange={(e) => handleFormChange(e)}>
                                                {tsbClassOfInvestigation.map((e, i) => {
                                                    return <option key={i} value={e}>{e}</option>
                                                })}

                                            </Form.Control>
                                        </Form.Group>

                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group controlId="fatalities">
                                            <Form.Label>Fatalities</Form.Label>
                                            <Form.Control type='number' value={cador.fatalities} onChange={(e) => handleFormChange(e)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group controlId="injuries">
                                            <Form.Label>Injuries</Form.Label>
                                            <Form.Control type='number' value={cador.injuries} placeholder='' onChange={(e) => handleFormChange(e)}></Form.Control>
                                        </Form.Group>
                                    </Col>


                                </Row>
                                <Row>
                                    <Col sm={12}>
                                    <Form.Group controlId="occurrenceEventInfo">
                                        <Form.Label>Occurrence Event Information</Form.Label>
                                        <Form.Control as='select' multiple value={cador.occurrenceEventInfo} onChange={selectedOptionsChange}>
                                            {occurrenceEventInformation.map((e,i) => {
                                                return <option key={i} value={e}>{e}</option>
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                    </Col>
                                </Row>

                                {/******** Event Information ********/}
                                <Row className='mt-4'>
                                    <Col sm={12} className='mb-3 h4 text-danger'>Aircraft Information</Col>
                                    <Col sm={12} md={4}>
                                        {aircraftInfo &&
                                            <Form.Group controlId="aircraftRegistration">
                                                <Form.Label>Registration Mark</Form.Label>
                                                <Form.Control
                                                    as='select'
                                                    placeholder=''

                                                    value={cador.aircraftRegistration}
                                                    onChange={(d) => {
                                                        let aircraft = aircraftInfo.filter(a => a.AIRCRAFT === d.target.value)
                                                        handleFormChange(d)
                                                    }
                                                    }
                                                >
                                                    <>
                                                        {aircraftInfo.sort((a, b) => a.AIRCRAFT < b.AIRCRAFT ? -1 : 1).map((e, key) => {
                                                            return <option key={key} value={e.AIRCRAFT}>{e.AIRCRAFT}</option>
                                                        })}

                                                    </>
                                                </Form.Control>
                                            </Form.Group>
                                        }

                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group controlId="flightRules">
                                            <Form.Label>Flight Rules</Form.Label>
                                            <Form.Control as='select' value={cador.flightRules} onChange={(e) => handleFormChange(e)}>
                                                <option value='VFR'>VFR</option>
                                                <option value='IFR'>IFR</option>
                                            </Form.Control>
                                        </Form.Group>

                                    </Col>

                                    <Col sm={12} md={4}>
                                        <Form.Group controlId="phaseOfFlight">
                                            <Form.Label>Phase of Flight</Form.Label>
                                            <Form.Control as='select' value={cador.phaseOfFlight} onChange={(e) => handleFormChange(e)}>
                                                {phaseOfFlight.map((e, i) => {
                                                    return <option key={i} value={e} index={i}>{e}</option>

                                                })}

                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col sm={12} md={3}>
                                        <Form.Group controlId="damage">
                                            <Form.Label>Damage</Form.Label>
                                            <Form.Control as='select' value={cador.damage} onChange={(e) => handleFormChange(e)}>
                                                {damage.map((e, i) => {
                                                    return <option key={i} value={e}>{e}</option>
                                                })}

                                            </Form.Control>
                                        </Form.Group>

                                    </Col>

                                    <Col sm={12} md={9}>
                                        <Form.Group controlId="aircraftEventInfo">
                                            <Form.Label>Aircraft Event Information</Form.Label>
                                            <Form.Control as='select' multiple value={cador.aircraftEventInfo} onChange={selectedOptionsChange}>
                                                {aircraftEventInfo.map((e, i) => {
                                                    return <option key={i} value={e}>{e}</option>
                                                })}
                                            </Form.Control>
                                            <Form.Text className="text-muted">Hold CTRL key and click to select multiple items.</Form.Text>

                                        </Form.Group>
                                    </Col>

                                </Row>
                                {/******** Occurrence Summary ********/}

                                <Row>
                                    <Col sm={12} className='text-danger mb-3 h4'>Occurrence Summary</Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={3}>
                                        <Form.Group controlId="dateEntered">
                                            <Form.Label>Date Entered</Form.Label>
                                            <Form.Control type='date' value={cador.dateEntered} onChange={(e) => handleFormChange(e)}></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={9}>
                                        <Form.Group controlId='narrative'>
                                            <Form.Label>Narrative</Form.Label>
                                            <Form.Control as='textarea' value={cador.narrative} onChange={(e) => handleFormChange(e)}></Form.Control>
                                        </Form.Group>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} className='text-danger mb-3 h4'>Supplemental Information</Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12}>
                                        <Form.Group controlId="navErrors">
                                            <Form.Label>Navigation Errors</Form.Label>
                                            <Form.Control as='select' multiple value={cador.navErrors} onChange={selectedOptionsChange}>
                                                {navErrors.map((e, i) => {
                                                    return <option key={i} value={e}>{e}</option>
                                                })}
                                            </Form.Control>
                                            <Form.Text className="text-muted">Hold CTRL key and click to select multiple items.</Form.Text>

                                        </Form.Group>
                                    </Col>
                                </Row>



                            </Form>
                        </Card.Body>

                        <Card.Footer>
                            <Row>
                                <Col sm={6}>
                                    <div className="text-muted">
                                        Last Updated {moment(cador.updatedAt).fromNow()}
                                        {cador.lastModifiedBy &&
                                         <>
                                            <span> by {cador.lastModifiedBy}</span>
                                         </>
                                        }
                                    
                                    
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className="text-muted text-right">
                                        Created {moment(cador.createdAt).format('DD-MMM-YYYY @ HH:mm')} 
                                        {cador.createdBy &&
                                         <>
                                            <span> by {cador.createdBy}</span>
                                         </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                
                                <Col sm={12} md={2}>
                                    {!saved &&
                                        <>
                                            <Button disabled={loading} variant='success' size='sm' className="mx-2 my-1" type="submit" onClick={handleUpdateCador}>Save</Button>
                                            <Button disabled={loading} variant='warning' size='sm' className="mx-2 my-1" onClick={handleCancel}>Cancel</Button>
                                        </>
                                    }
                                    {saved &&
                                        <>
                                            {!deleteConfirm &&
                                                <>
                                                    <Button disabled={loading} variant='secondary' size='sm' className="mx-2 my-1" type="submit" onClick={handleClose}>Close</Button>
                                                    <Button disabled={loading} variant='outline-danger' size='sm' className="mx-2 my-1" onClick={() => setDeleteConfirm(true)}>Delete</Button>
                                                </>
                                            }
                                            {deleteConfirm &&
                                                <>
                                                    <Button disabled={loading} variant='warning' size='sm' className="mx-2 my-1" type="submit" onClick={handleCancel}>Cancel</Button>
                                                    <Button disabled={loading} variant='danger' size='sm' className="mx-2 my-1" onClick={handleDeleteCador}>Confirm Delete?</Button>

                                                </>
                                            }

                                        </>
                                    }
                                </Col>
                                <Col sm={12} md={10}>
                                    {error && <Alert variant="danger">{error}</Alert>}
                                    {success && <Alert variant="success">{success}</Alert>}
                                </Col>

                            </Row>



                        </Card.Footer>

                    </Card>
                }
            </Modal>

        </div>
    )
}
