import React from "react";
// import './dashboard.css';
import { Container, Row, Col } from "react-bootstrap";
import { DailyFlightStats } from "../components/Stats/DailyFlightStats";
import { WeatherStatus } from "../components/Weather/WeatherStatus";
import WxCam from "../components/Weather/WxCam";

import { useFlights } from "../components/Contexts/FlightContext";
import { cameraList } from "../config/cameraList";
import { TrafficCam } from "../components/Weather/TrafficCam";

const totalDailyHours = (base, dailyHours) => {
  if (!dailyHours) return;
  if (!dailyHours[base]) return;
  let hours = 0;
  let keys = Object.keys(dailyHours[base].resId);
  keys.forEach((key) => {
    hours = hours + dailyHours[base].resId[key];
  });
  return hours;
};

const Dashboard = () => {
  const { dailyHours, aircraftInfo, metar } = useFlights();
  const METAR_UPDATE_INTERVAL = 1000 * 60 * 2; //Refresh METAR data every 2 minutes.

  return (
    <Container fluid className="dash-container">
      <div className="dash-section">
        {/* Daily Flight hours stats */}
        <Row className="dash-row">
          <Col sm={12} className="dash-col">
            <span className="h2">Weather Status</span>
          </Col>
        </Row>

        <Row className="dash-row">
          <Col sm={6} className="dash-col">
            <WeatherStatus base={"CYFC"} weather={metar} />
          </Col>
          <Col sm={6} className="dash-col">
            <WeatherStatus base={"CYQM"} weather={metar} />
          </Col>
          <Col sm={6} className="dash-col">
            <WeatherStatus base={"CYRT"} weather={metar} />
          </Col>
          <Col sm={6} className="dash-col">
            <WeatherStatus base={"CYTH"} weather={metar} />
          </Col>
        </Row>

        <Row className="dash-row"></Row>
      </div>
      <hr />
      <div className="dash-section">
        {/* CYFC Airport Weather Information */}
        <Row className="dash-row">
          <Col sm={12} className="dash-col">
            <span className="h2">Flight Stats</span>
          </Col>
        </Row>
        <Row className="dash-row">
          <Col sm={6} className="dash-col">
            <DailyFlightStats
              base={"CYFC"}
              totalHours={
                Math.round(totalDailyHours("CYFC", dailyHours) * 10) / 10
              }
            />
          </Col>

          <Col sm={6} className="dash-col">
            <DailyFlightStats
              base={"CYQM"}
              totalHours={
                Math.round(totalDailyHours("CYQM", dailyHours) * 10) / 10
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="dash-col">
            <DailyFlightStats
              base={"CYRT"}
              totalHours={
                Math.round(totalDailyHours("CYRT", dailyHours) * 10) / 10
              }
            />
          </Col>
          <Col sm={6} className="dash-col">
            <DailyFlightStats
              base={"CYTH"}
              totalHours={
                Math.round(totalDailyHours("CYTH", dailyHours) * 10) / 10
              }
            />
          </Col>
        </Row>

        <Row className="dash-row">
          <Col sm={12} md={6} lg={3} className="dash-col">
            <WxCam direction="N" />
          </Col>
          <Col sm={12} md={6} lg={3} className="dash-col">
            <WxCam direction="E" />
          </Col>
          <Col sm={12} md={6} lg={3} className="dash-col">
            <WxCam direction="SW" />
          </Col>
          <Col sm={12} md={6} lg={3} className="dash-col">
            <WxCam direction="W" />
          </Col>
        </Row>
        <Row className="dash-row">
          {cameraList.features.map((cam, index) => {
            return (
              <Col key={index} sm={12} md={6} lg={3} className="dash-col">
                <TrafficCam cam={cam.properties} />
              </Col>
            );
          })}
        </Row>
      </div>
    </Container>
  );
};

export default Dashboard;
