import React from 'react'
import {Container} from 'react-bootstrap'

export const NotAuth = () => {
    return (
        <Container className='mt-5 pt-5 align-items-center justify-content-center'>

            <div className='mt-5 h2 text-center'>
                You are not authorized to view this page.
            </div>
        </Container>
    )
}
