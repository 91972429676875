import React from "react";
import { Popup } from "react-map-gl";


const flightCategoryBadge = (metar) => {

  if (metar.flight_category === 'VFR') return <span className='badge badge-vfr'>VFR</span>
  if (metar.flight_category === 'MVFR') return <span className='badge badge-mvfr'>MVFR</span>
  if (metar.flight_category === 'IFR') return <span className='badge badge-ifr'>IFR</span>
  if (metar.flight_category === 'LIFR') return <span className='badge badge-lifr'>LIFR</span>
}



const FlightPopup = ({ wx, passChildData }) => {
    
  return (
    <Popup
      
      latitude={parseFloat(wx.latitude)}
      longitude={parseFloat(wx.longitude)}
      onClose={() => {
        passChildData(null);
      }}
    >
      <div className="wxpopup">
        <h5>{wx.station_id} - {flightCategoryBadge(wx)}</h5>
        {wx.raw_text}
      </div>
    </Popup>
  );
};

export default FlightPopup;
