import React from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../Contexts/AuthContext";

import {
  faHome,
  faMap,
  faPlaneDeparture,
  faList,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

export default function BottomNav() {
  const { currentUser } = useAuth();
  return (
    <Navbar fixed="bottom" className="navbar-default, bottomNav" bg="dark">
      <Container>
        {currentUser.claims.maintenance && (
          <Nav.Link
            className="bottomNavLinks"
            as={NavLink}
            to="/maint"
            href="/maint"
          >
            <FontAwesomeIcon icon={faWrench} size="lg" />
          </Nav.Link>
        )}
        {currentUser.claims.flightOps && (
          <>
            {/* Planned Flights DropUP */}
            <NavDropdown
              title={
                <div style={{ display: "inline-block" }}>
                  <FontAwesomeIcon icon={faList} size="lg" />
                </div>
              }
              id="collapsible-nav-dropdown"
              className="bottomNavLinks dropup"
              bg="light"
            >
              <NavDropdown.ItemText className="bottomNavLinks h4 text-light text-center">
                Planned
              </NavDropdown.ItemText>

              <NavDropdown.Item
                as={NavLink}
                to="/plannedcyqm"
                href="/plannedcyqm"
                activeClassName="bg-primary rounded"
                className="bottomNavLinks text-center border border-secondary rounded my-2 py-2"
              >
                CYQM
              </NavDropdown.Item>

              <NavDropdown.Item
                as={NavLink}
                to="/plannedcyfc"
                href="/plannedcyfc"
                activeClassName="bg-primary rounded"
                className="bottomNavLinks text-center border border-secondary rounded my-2 py-2"
              >
                CYFC
              </NavDropdown.Item>

              <NavDropdown.Item
                as={NavLink}
                to="/plannedcyrt"
                href="/plannedcyrt"
                activeClassName="bg-primary rounded"
                className="bottomNavLinks text-center border border-secondary rounded my-2 py-2"
              >
                CYRT
              </NavDropdown.Item>

              <NavDropdown.Item
                as={NavLink}
                to="/plannedcyth"
                href="/plannedcyth"
                activeClassName="bg-primary rounded"
                className="bottomNavLinks text-center border border-secondary rounded my-2 py-2"
              >
                CYTH
              </NavDropdown.Item>
            </NavDropdown>
          </>
        )}
        {/* Home */}
        <Nav.Link
          exact={true}
          className="bottomNavLinks"
          as={NavLink}
          to="/"
          href="/"
        >
          <FontAwesomeIcon icon={faHome} size="lg" />
        </Nav.Link>
        {currentUser.claims.flightOps && (
          <>
            {/* Active Flights DropUP */}
            <NavDropdown
              title={
                <div style={{ display: "inline-block" }}>
                  <FontAwesomeIcon icon={faPlaneDeparture} size="lg" />
                </div>
              }
              id="collapsible-nav-dropdown"
              className="bottomNavLinks dropup"
              bg="light"
            >
              <NavDropdown.ItemText className="bottomNavLinks h4 text-light text-center">
                Active
              </NavDropdown.ItemText>

              <NavDropdown.Item
                as={NavLink}
                to="/departedcyqm"
                href="/departedcyqm"
                activeClassName="bg-primary rounded"
                className="bottomNavLinks text-center border border-secondary rounded my-2 py-2"
              >
                CYQM
              </NavDropdown.Item>

              <NavDropdown.Item
                as={NavLink}
                to="/departedcyfc"
                href="/departedcyfc"
                activeClassName="bg-primary rounded"
                className="bottomNavLinks text-center border border-secondary rounded my-2 py-2"
              >
                CYFC
              </NavDropdown.Item>

              <NavDropdown.Item
                as={NavLink}
                to="/departedcyrt"
                href="/departedcyrt"
                activeClassName="bg-primary rounded"
                className="bottomNavLinks text-center border border-secondary rounded my-2 py-2"
              >
                CYRT
              </NavDropdown.Item>

              <NavDropdown.Item
                as={NavLink}
                to="/departedcyth"
                href="/departedcyth"
                activeClassName="bg-primary rounded"
                className="bottomNavLinks text-center border border-secondary rounded my-2 py-2"
              >
                CYTH
              </NavDropdown.Item>
            </NavDropdown>

            {/* map */}

            <Nav.Link
              className="bottomNavLinks"
              as={NavLink}
              to="/map"
              href="/map"
            >
              <FontAwesomeIcon icon={faMap} size="lg" />
            </Nav.Link>
          </>
        )}
      </Container>
    </Navbar>
  );
}
