import moment from "moment-timezone";


export const filterFlights = (
  flights,
  startOffsetMinutes,
  endOffsetMinutes
) => {
  return flights.filter(
    (f) => f.ACTIVITY_TYPE === "Flight" && f.STATUS === "Ramp Out"

    // f.ACTIVITY_START.isBefore(newestTime) &&
    // f.ACTIVITY_START.isAfter(oldestTime)
  );
};

export const sortFlights = (flights) => {
  return flights.sort((a, b) => a.ACTIVITY_START - b.ACTIVITY_START);
};



export const processFlights = (flights) => {
  flights.forEach((flight) => {


    let tz
    
    if (flight.LOCATION === "MFC Moncton" || flight.LOCATION === "MFC Fredericton") {
      tz = "America/Moncton";
    }

    if (flight.LOCATION === "MFC Rankin Inlet" || flight.LOCATION === "MFC Thompson") {
      tz = "America/Winnipeg";
    }

    //Change ACTIVITY_START and ACTIVITY_DUEBACK to MOMENTS and change LOCATION to AIRPORT ID
    flight.ACTIVITY_START = moment.tz(
      flight.ACTIVITY_START,
      "DD MMM YYYY hh:mm",
      tz
    );
    flight.ACTIVITY_DUEBACK = moment.tz(
      flight.ACTIVITY_DUEBACK,
      "DD MMM YYYY hh:mm",
      tz
    );

    //Add TTG value for each flight

    let a = flight.ACTIVITY_DUEBACK;
    let b = new moment().tz(tz);
    flight.TTG = a.diff(b, "minutes");

    if (flight.LOCATION === "MFC Moncton") {
      flight.LOCATION = "CYQM";
    }
    if (flight.LOCATION === "MFC Fredericton") {
      flight.LOCATION = "CYFC";
    }
    if (flight.LOCATION === "MFC Rankin Inlet") {
      flight.LOCATION = "CYRT";
    }
    if (flight.LOCATION === "MFC Thompson") {
      flight.LOCATION = "CYTH";
    }
  });
  return flights;
};
