import React from "react";
import { Marker } from "react-map-gl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faVideo } from "@fortawesome/free-solid-svg-icons";

export const CameraMarker = ({ cam, viewport, passChildData }) => {
  return (
    <Marker
      latitude={cam.lat}
      longitude={cam.long}
      className={"marker"}
      anchor={"center"}
      offsetLeft={viewport.zoom}
      offsetTop={viewport.zoom * -2}
    >
      <FontAwesomeIcon
        color={"#bfbfbf"}
        icon={faVideo}
        onClick={(e) => {
          e.preventDefault();
          passChildData(cam);
        }}
        style={{
          height: `${1.3 * viewport.zoom}px`,
          width: `${1.3 * viewport.zoom}px`,
        }}
      />
    </Marker>
  );
};
