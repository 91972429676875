import React from 'react';

import { Image, Card, } from 'react-bootstrap';

export function TrafficCam( {cam} ) {

    return (
        <>
            <Card
                bg="dark"
                className='shadow'
            >
                <Card.Header>
                    <Card.Title>
                        {cam.name}
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Image
                        fluid
                        className="pl-2 pr-2 pd-2 pb-2"
                        src={cam.url}
                        rounded
                    />
                </Card.Body>
                <Card.Footer>
                </Card.Footer>
            </Card>
        </>
    );
}
