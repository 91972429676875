import React, { useEffect, useState } from 'react'
import { PlannedFlightTable } from './PlannedFlightTable'
import { FlightDetails } from '../Common/FlightDetails'
import { getTalonFlights } from '../../../api/api'
import { filterFlights, sortFlights, processFlights, addCxReason } from './functions/plannedFlightFunctions'
import { useInterval } from '../../../hooks/useInterval'

import '../Common/fid.css'


export const Planned = ({location, kiosk}) => {
    const [flights, setFlights] = useState();
    

    const FID_REFRESH_INTERVAL = 1000 * 30 //Set refresh interval for FID data to 30 seconds.


    const [modalOptions, setModalOptions] = useState(
        {
            show: false,
            id: null,
            activityIDFilter: null
        }
    );

    useEffect(() => {
        let startOffsetMin = 60;
        let endOffsetMin = 720;

        getTalonFlights()
        .then(data => processFlights(data))
        .then(data => filterFlights(data, startOffsetMin, endOffsetMin))
        .then(data => sortFlights(data))
        .then(data => addCxReason(data))
        .then(data => setFlights(data))
        .catch(err => console.log(err))
        
        return () => {
            setFlights({});
        };

    }, []);

    useInterval(() => {
        let startOffsetMin = 60;
        let endOffsetMin = 720;
        getTalonFlights()
        .then(data => processFlights(data))
        .then(data => filterFlights(data, startOffsetMin, endOffsetMin))
        .then(data => sortFlights(data))
        .then(data => addCxReason(data))
        .then(data => setFlights(data))
        .catch(err => console.log(err))
        return () => {
            setFlights({});
        };

    }, FID_REFRESH_INTERVAL);

    const flightDetailsToParent = (id) => {

        let options = {
            show: true,
            id: id,
            activityIDFilter: id
        };

        setModalOptions(options);
    }

    const closeFlightDetails = () => {
        let options = {
            show: false,
            id: null,
            activityIDFilter: null
        };

        setModalOptions(options);

    }

    return (
        <>
            {kiosk &&
                <div className='table-title h5'>Cancelled Flights - {location} </div>
            }

            {!kiosk && 
                <div className='table-title h5'>Planned Flights - {location} </div>
            }
            <PlannedFlightTable kiosk={kiosk} flights={flights} location={location} flightDetailsToParent={flightDetailsToParent} />

            {modalOptions.show &&
                <FlightDetails kiosk={kiosk} flight={flights.filter(f => f.ACTIVITY_ID === modalOptions.id)[0]} modalOptions={modalOptions} closeFlightDetails={closeFlightDetails} />
            }
        </>


    )
}
