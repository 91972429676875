export const cameraList = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-64.6816, 46.1278],
      },
      properties: {
        name: "Dieppe/Moncton",
        url: "https://www.gnb.ca/0113/cameras/cam-images/RNBDI.jpg?random=1617413139980",
        province: "NB",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-64.750108, 46.095102],
      },
      properties: {
        name: "Dieppe: at rue Champlain St and ave Acadie Ave",
        url: "https://images-webcams.windy.com/78/1302746178/current/full/1302746178.jpg",
        province: "NB",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [-60.31297058539576, 53.29688165145948],
      },
      properties: {
        name: "Happy Valley Goose Bay - View from Elizabeth Goudie Building",
        url: "https://www.roads.gov.nl.ca/cameras/sites/goosebay/current.jpg",
        province: "NL",
      },
    },
  ],
};
