import React from 'react';

const FlightDataTag = ({ flightData }) => {

    return (
        <>
            <div className="flightlabel">
                {flightData.type} {flightData.ident}
            </div>
            <div className="flightlabel">
                {flightData.origin} - {flightData.destination}
            </div>
            <div className="flightlabel">
                A{flightData.altitude} {flightData.groundspeed}kts
            </div>
        </>
    )

};

export default FlightDataTag;
