import { Source, Layer } from "react-map-gl";
import axios from "axios";
import { useState, useEffect } from "react";
import { useInterval } from "../../hooks/useInterval";

export function RadarSource(props) {
  const [apiData, setApiData] = useState();

  async function getApiData() {
    const { data } = await axios.get("https://api.rainviewer.com/public/weather-maps.json");
    const apiDataTemp = data;
    setApiData(apiDataTemp);
    console.log("Radar Update");
  }
  const radarUpdateInterval = useEffect(() => {
    getApiData();
  }, []);

  useInterval(() => {
    getApiData();
    return () => {
      getApiData();
    };
  }, [1000 * 60]); // 1 min update interval

  if (!apiData) return <></>;
  const radarTilesSource = {
    id: "radarsource",
    type: "raster",
    tiles: [apiData.host + apiData.radar.past[apiData.radar.past.length - 1].path + "/256/{z}/{x}/{y}/2/1_1.png"],
    tileSize: 256,
  };

  const layer = {
    type: "raster",
    source: "radarsource",
    paint: { "raster-opacity": 0.5 },
    layout: { visibility: props.layerVisibility },
  };
  return (
    <>
      <Source {...radarTilesSource}>
        <Layer {...layer} />
      </Source>
    </>
  );
}
