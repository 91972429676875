import React from "react";
import { Popup } from "react-map-gl";
import {Table} from 'react-bootstrap'

const FlightPopup = ({ flight, passChildData }) => {
  return (
    <Popup
      
      latitude={flight.latitude}
      longitude={flight.longitude}
      onClose={() => {
        passChildData(null);
      }}
    >
      <div className="popup">
        <h5>Flight Info</h5>
        <Table                 
          size="sm"
          borderless
          resonsive="lg"
          className=""
          
        >
          <tbody className="text-light">
            <tr>
              <td className="tableitem">PIC: </td>
              <td className="tablevalue">{flight.TALON_PIC}</td>
            </tr>
            <tr>
              <td className="tableitem">STUDENT: </td>
              <td className="tablevalue">{flight.TALON_STUDENT1}</td>
            </tr>
            <tr>
              <td className="tableitem">UNIT: </td>
              <td className="tablevalue">{flight.TALON_STUDENT1_UNIT}</td>
            </tr>
            <tr>
              <td className="tableitem">DEST: </td>
              <td className="tablevalue">{flight.TALON_DESTINATION}</td>
            </tr>
            <tr>
              <td className="tableitem">DUE BACK: </td>
              <td className="tablevalue">{flight.TALON_ACTIVITY_DUEBACK}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Popup>
  );
};

export default FlightPopup;
