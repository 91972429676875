import React from "react";
import { Popup } from "react-map-gl";
import { Image } from "react-bootstrap";

export const CameraMarkerPopup = ({ cam, passChildData }) => {
  return (
    <Popup
      latitude={cam.lat}
      longitude={cam.long}
      onClose={() => {
        passChildData(null);
      }}
    >
      <div>
        <Image fluid className="pl-2 pr-2 pd-2 pb-2" src={cam.url} rounded />
      </div>
    </Popup>
  );
};
