//Construct a table using a TALON flight object that includes the following columns: 
// AIRCRAFT START  DUE  UNIT  INSTRUCTOR  STUDENT  STATUS  TTG  BASE

import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import LoadingBar from '../../LoadingBar/LoadingBar';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function truncate(input) {
    if (input.length > 6) {
        return input.substring(0, 6) + '...';
    }
    return input;
};

const tableWidthBreak = 750; //Screen width to start reformatting table to display better on smaller screens

export const DepartedFlightTable = ({ flights, location, flightDetailsToParent, kiosk }) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {kiosk &&
                <Table
                    size="sm"
                    striped
                    // hover
                    borderless
                    resonsive="xl"
                    className="text-center"
                    id="flights"
                    variant='dark'
                >

                    <thead className="text-light bg-dark">
                        <tr>
                            <th>Aircraft</th>
                            <th>Dest</th>
                            <th>Unit</th>
                            <th>TTG</th>
                        </tr>
                    </thead>
                    <tbody className="text-light">

                    {flights && flights.filter(f => f.LOCATION === location)
                        .sort((a, b) => a.TTG < b.TTG ? -1 : 1)
                        .map((f, index) => (
                            <React.Fragment key={index} >
                                <tr
                                    className="text-light"
                                    onClick={() => flightDetailsToParent(f.ACTIVITY_ID)}
                                    key={f.ACTIVITY_ID}
                                >
                                    <td>
                                        {
                                            f.RESOURCE_NAME ? f.RESOURCE_NAME : '--'
                                        }
                                    </td>
                                    <td>{f.DESTINATION}</td>
                                    <td>{f.STUDENT1_UNIT}</td>

                                    {windowDimensions.width > tableWidthBreak &&
                                        <>

                                            {(f.TTG <= 0) && <td className="text-danger">{f.TTG}</td>}
                                            {(f.TTG > 0 && f.TTG < 10) && <td className="text-warning">{f.TTG}</td>}
                                            {(f.TTG >= 10) && <td className="">{f.TTG}</td>}

                                        </>
                                    }



                                </tr>
                            </React.Fragment>
                        ))}
                </tbody>

                </Table>
            }


            {!kiosk &&
                <Table
                size="sm"
                striped
                // hover
                borderless
                resonsive="xl"
                className="text-center"
                id="flights"
                variant='dark'
            >
                <thead className="text-light bg-dark">
                    <tr>
                        <th>Aircraft</th>
                        {windowDimensions.width > tableWidthBreak && <th>Dest</th>}
                        <th>Due</th>
                        {windowDimensions.width > tableWidthBreak && <th>UNIT</th>}
                        <th>Instructor</th>
                        <th>Student</th>
                        <th>Status</th>
                        {windowDimensions.width > tableWidthBreak && <th>TTG</th>}
                    </tr>
                </thead>

                <tbody className="text-light">

                    {flights && flights.filter(f => f.LOCATION === location)
                        .sort((a, b) => a.TTG < b.TTG ? -1 : 1)
                        .map((f, index) => (
                            <React.Fragment key={index} >
                                <tr
                                    className="text-light"
                                    onClick={() => flightDetailsToParent(f.ACTIVITY_ID)}
                                    key={f.ACTIVITY_ID}
                                >
                                    <td>
                                        {
                                            f.RESOURCE_NAME ? f.RESOURCE_NAME : '--'
                                        }
                                    </td>
                                    {windowDimensions.width > tableWidthBreak && <td>{f.DESTINATION}</td>}
                                    <td>{f.ACTIVITY_DUEBACK.format('HH:mm').toString()}</td>
                                    {windowDimensions.width > tableWidthBreak && <td>{f.STUDENT1_UNIT}</td>}

                                    <td>
                                        {
                                            f.PIC_LNAME
                                                ? (windowDimensions.width < tableWidthBreak ? truncate(f.PIC_LNAME) : f.PIC_LNAME + ', ' + f.PIC_FNAME.slice(0, 1))
                                                : '--'}

                                    </td>

                                    <td>
                                        {
                                            f.STUD1_LNAME
                                                ? (windowDimensions.width < tableWidthBreak ? truncate(f.STUD1_LNAME) : f.STUD1_LNAME + ', ' + f.STUD1_FNAME.slice(0, 1))
                                                : '--'
                                        }
                                    </td>



                                    <td>
                                        {(f.STATUS === 'Ramp Out') &&
                                            <h5>
                                                <span className="badge badge-warning text-light shadow">
                                                    ROUT
                                            </span>
                                            </h5>}

                                        {(f.STATUS === 'Cancelled') &&
                                            <h5>
                                                <span className="badge badge-danger text-light shadow">
                                                    CNXF
                                            </span>
                                            </h5>}

                                        {(f.STATUS === 'Authorized') &&
                                            <h5>
                                                <span className="badge badge-success text-light shadow">
                                                    AUTH
                                            </span>
                                            </h5>}

                                        {(f.STATUS === 'Ops Check In') &&
                                            <h5>
                                                <span className="badge badge-primary text-light shadow">
                                                    CKIN
                                            </span>
                                            </h5>}

                                        {(f.STATUS === 'Scheduled') &&
                                            <h5>
                                                <span className="badge badge-light text-dark shadow">
                                                    SKED
                                            </span>
                                            </h5>}


                                    </td>
                                    {windowDimensions.width > tableWidthBreak &&
                                        <>

                                            {(f.TTG <= 0) && <td className="text-danger">{f.TTG}</td>}
                                            {(f.TTG > 0 && f.TTG < 10) && <td className="text-warning">{f.TTG}</td>}
                                            {(f.TTG >= 10) && <td className="">{f.TTG}</td>}

                                        </>
                                    }



                                </tr>
                            </React.Fragment>
                        ))}
                </tbody>
            </Table>
            }
            
            {(!flights || flights.length < 1) &&
                <div className="loadingBar">
                    <LoadingBar />
                    <h6>No flights to display right now...</h6>
                </div>
            }
        </>

    )
}
