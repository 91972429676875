import React, { useState, useEffect } from 'react'
import { Modal, Card, Button, Form, Alert, Row, Col, Table } from 'react-bootstrap'
import { createCador } from '../../api/cadorAPI'
import map from 'lodash/map';
import { getTalonFlights, getInstructorId } from '../../api/api'

import { cadorOccurenceCategory, tsbClassOfInvestigation, phaseOfFlight, damage, aircraftEventInfo, initialFormData, occurrenceEventInformation, navErrors } from '../../config/cadorFormData'


export const NewCadorModal = ({ newCadorModalOpts, closeNewCadorModal, aircraftInfo }) => {
    const [loading, setLoading] = useState(false);
    const [saved, setSaved] = useState(true)
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [selectedAircraft, setSelectedAircraft] = useState();
    const [formData, setFormData] = useState(initialFormData);
    // const [selectedValues, setSelectedValues] = useState([]);
    const [talonFlights, setTalonFlights] = useState();
    const [selected, setSelected] = useState(false);




    useEffect(() => {
        if (formData.occurrenceTime && formData.occurrenceDate && formData.aircraftRegistration) {

            //Get TALON Flight Data around the OccurrenceDate and Time and for the given Aircraft
            //then setTalonFlights(data)
            //cador.occurrenceTimeStamp = `${cador.occurrenceDate}T${cador.occurrenceTime}:00.000Z`;
            (async () => {
                let occurrenceTimeStamp = `${formData.occurrenceDate}T${formData.occurrenceTime}:00.000Z`;

                getTalonFlights(occurrenceTimeStamp).then((data) => {
                    data = data.filter(d => d.ACTIVITY_TYPE === 'Flight' && d.STATUS === 'Completed' && d.RESOURCE_NAME === formData.aircraftRegistration)
                    setTalonFlights(data)
                });
            })()
            return () => {
                getTalonFlights({});
            }
        }
    }, [formData])


    const handleCancel = () => {
        setFormData(initialFormData);
        closeNewCadorModal();
        setError(null)
        setTalonFlights(null)


    }

    const handleClose = () => {
        closeNewCadorModal();
        setSuccess(null)
        setError(null)
        setTalonFlights(null)

    }

    const saveCador = async () => {

        setLoading(true);
        try {
            let res = await createCador(formData)
            if (res.isAxiosError) {

                let message = res.response.data.message

                throw new Error(message)
            }
            setSaved(true)
            setError(false)
            setSuccess('Cador Saved Successfully')



        } catch (error) {
            setError(error.message)
            console.log('Error', error)
        }
        setLoading(false);
        //setFormData(initialFormData);


    }

    const handleFormChange = (e) => {

        const fd = { ...formData }
        fd[e.target.id] = e.target.value;
        setFormData(fd)
        setSaved(null)
    }

    const selectedOptionsChange = (e) => {
        const fd = { ...formData }
        fd[e.target.id] = map(e.target.selectedOptions, option => option.value)
        setFormData(fd)

    }

    const handleRowClick = async (e) => {


        let instructorId = await getInstructorId(e)

        const fd = { ...formData }
        fd.TALON_ACT_ID = e
        fd.instructorId = instructorId.data
        setFormData(fd)
        setSelected(e)
    }

    return (
        <div>
            <Modal

                size=''
                dialogClassName="modalStyle"
                animation={true}
                show={newCadorModalOpts.show}
                onHide={closeNewCadorModal}
                align-items-center="true"
                justify-content-center="true"
                className='mt-3 mb-3'
                backdrop='static'

            >
                <Card className="bg-dark">
                    <Card.Header>
                        <Card.Title>New CADOR</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            {/********* Cador Summary ********/}
                            <Row>
                                <Col className="mb-3 h4 text-danger" sm={12}>
                                    Cador Summary
                                 </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col sm={12} md={4}>
                                    <Form.Group controlId="cadorNumber">
                                        <Form.Label>CADORS Number</Form.Label>
                                        <Form.Control type="text" placeholder='CADOR Number' onChange={handleFormChange}></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={4}>
                                    <Form.Group controlId="smsId">
                                        <Form.Label>SMS ID</Form.Label>
                                        <Form.Control type="text" placeholder='SMS ID' onChange={handleFormChange}></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={4}>
                                    <Form.Group controlId="occurrenceCategory">
                                        <Form.Label>Occurrence Category</Form.Label>
                                        <Form.Control as='select' multiple onChange={selectedOptionsChange}>
                                            {cadorOccurenceCategory.map((e, i) => {
                                                return <option key={i} value={e}>{e}</option>
                                            })}
                                        </Form.Control>
                                        <Form.Text className="text-muted">Hold CTRL key and click to select multiple items.</Form.Text>
                                    </Form.Group>

                                </Col>

                            </Row>

                            {/******** Occurrence Information ********/}
                            <Row>
                                <Col className="mb-3 h4 text-danger" sm={12}>Occurrence Information</Col>
                                <Col sm={12} md={3}>
                                    <Form.Group controlId="occurrenceType">
                                        <Form.Label>OccurrenceType</Form.Label>
                                        <Form.Control as='select' onChange={handleFormChange}>
                                            <option value="Incident">Incident</option>
                                            <option value="Accident">Accident</option>
                                        </Form.Control>
                                    </Form.Group>

                                </Col>
                                <Col sm={12} md={3}>
                                    <Form.Group controlId="occurrenceDate">
                                        <Form.Label>Occurrence Date</Form.Label>
                                        <Form.Control type='date' placeholder='' onChange={handleFormChange}></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={3}>
                                    <Form.Group controlId="occurrenceTime">
                                        <Form.Label>Occurrence Time (Z)</Form.Label>
                                        <Form.Control type='time' placeholder='' onChange={handleFormChange}></Form.Control>
                                    </Form.Group>

                                </Col>
                                <Col sm={12} md={3}>
                                    <Form.Group controlId="dayOrNight">
                                        <Form.Label>Day or Night:</Form.Label>
                                        <Form.Control as='select' placeholder='' defaultValue='Not Specified' onChange={handleFormChange}>
                                            <option value="Day">Day</option>
                                            <option value="Night">Night</option>
                                            <option value="Not Specified">Not Specified</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={4}>
                                    <Form.Group controlId="aerodromeID">
                                        <Form.Label>Aerodrome ID</Form.Label>
                                        <Form.Control type='text' placeholder='' onChange={handleFormChange}></Form.Control>
                                    </Form.Group>

                                </Col>

                                <Col sm={12} md={4}>
                                    <Form.Group controlId="occurrenceLocation">
                                        <Form.Label>Occurrence Location</Form.Label>
                                        <Form.Control type='text' placeholder='' onChange={handleFormChange}></Form.Control>
                                    </Form.Group>

                                </Col>
                                <Col sm={12} md={4}>
                                    <Form.Group controlId="reportedBy">
                                        <Form.Label>Reported By</Form.Label>
                                        <Form.Control type='text' placeholder='' onChange={handleFormChange}></Form.Control>
                                    </Form.Group>

                                </Col>

                            </Row>

                            <Row>
                                <Col sm={12} md={4}>
                                    <Form.Group controlId="tsbClassOfInvestigation">
                                        <Form.Label>TSB Class of Investigation</Form.Label>
                                        <Form.Control as='select' placeholder='' defaultValue={'Not Specified'} onChange={handleFormChange}>
                                            {tsbClassOfInvestigation.map((e, i) => {
                                                return <option key={i} value={e}>{e}</option>
                                            })}

                                        </Form.Control>
                                    </Form.Group>

                                </Col>
                                <Col sm={12} md={4}>
                                    <Form.Group controlId="fatalities">
                                        <Form.Label>Fatalities</Form.Label>
                                        <Form.Control type='number' defaultValue='0' onChange={handleFormChange}></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={4}>
                                    <Form.Group controlId="injuries">
                                        <Form.Label>Injuries</Form.Label>
                                        <Form.Control type='number' defaultValue='0' placeholder='' onChange={handleFormChange}></Form.Control>
                                    </Form.Group>
                                </Col>


                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId="occurrenceEventInfo">
                                        <Form.Label>Occurrence Event Information</Form.Label>
                                        <Form.Control as='select' multiple defaultValue={'Not Specified'} onChange={selectedOptionsChange}>
                                            {occurrenceEventInformation.map((e, i) => {
                                                return <option key={i} value={e}>{e}</option>
                                            })}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/******** Event Information ********/}
                            <Row className='mt-4'>
                                <Col sm={12} className='mb-3 h4 text-danger'>Aircraft Information</Col>
                                <Col sm={12} md={4}>
                                    {aircraftInfo &&
                                        <Form.Group controlId="aircraftRegistration">
                                            <Form.Label>Registration Mark</Form.Label>
                                            <Form.Control
                                                as='select'
                                                placeholder=''
                                                defaultValue=''
                                                onChange={(d) => {
                                                    let aircraft = aircraftInfo.filter(a => a.AIRCRAFT === d.target.value)
                                                    setSelectedAircraft(aircraft)
                                                    handleFormChange(d)
                                                }
                                                }
                                            >
                                                <>
                                                    {aircraftInfo.sort((a, b) => a.AIRCRAFT < b.AIRCRAFT ? -1 : 1).map((e, key) => {
                                                        return <option key={key} value={e.AIRCRAFT}>{e.AIRCRAFT}</option>
                                                    })}

                                                </>
                                            </Form.Control>
                                        </Form.Group>
                                    }

                                </Col>
                                <Col sm={12} md={4}>
                                    <Form.Group controlId="flightRules">
                                        <Form.Label>Flight Rules</Form.Label>
                                        <Form.Control as='select' placeholder='' defaultValue='VFR' onChange={handleFormChange}>
                                            <option value='VFR'>VFR</option>
                                            <option value='IFR'>IFR</option>
                                        </Form.Control>
                                    </Form.Group>

                                </Col>

                                <Col sm={12} md={4}>
                                    <Form.Group controlId="phaseOfFlight">
                                        <Form.Label>Phase of Flight</Form.Label>
                                        <Form.Control as='select' placeholder='' defaultValue='Parked' onChange={handleFormChange}>
                                            {phaseOfFlight.map((e, i) => {
                                                return <option key={i} value={e} index={i}>{e}</option>

                                            })}

                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col sm={12} md={3}>
                                    <Form.Group controlId="damage">
                                        <Form.Label>Damage</Form.Label>
                                        <Form.Control as='select' placeholder='' defaultValue='No Damage' onChange={handleFormChange}>
                                            {damage.map((e, i) => {
                                                return <option key={i} value={e}>{e}</option>
                                            })}

                                        </Form.Control>
                                    </Form.Group>

                                </Col>

                                <Col sm={12} md={9}>
                                    <Form.Group controlId="aircraftEventInfo">
                                        <Form.Label>Aircraft Event Information</Form.Label>
                                        <Form.Control as='select' multiple placeholder='' defaultValue={'Not Specified'} onChange={selectedOptionsChange}>
                                            {aircraftEventInfo.map((e, i) => {
                                                return <option key={i} value={e}>{e}</option>
                                            })}
                                        </Form.Control>
                                        <Form.Text className="text-muted">Hold CTRL key and click to select multiple items.</Form.Text>


                                    </Form.Group>
                                </Col>

                            </Row>
                            {/******** Occurrence Summary ********/}

                            <Row>
                                <Col sm={12} className='text-danger mb-3 h4'>Occurrence Summary</Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={3}>
                                    <Form.Group controlId="dateEntered">
                                        <Form.Label>Date Entered</Form.Label>
                                        <Form.Control type='date' placeholder='' onChange={(e) => handleFormChange(e)}></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={9}>
                                    <Form.Group controlId='narrative'>
                                        <Form.Label>Narrative</Form.Label>
                                        <Form.Control as='textarea' placeholder='' onChange={(e) => handleFormChange(e)}></Form.Control>
                                    </Form.Group>

                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className='text-danger mb-3 h4'>Supplemental Information</Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12}>
                                    <Form.Group controlId="navErrors">
                                        <Form.Label>Navigation Errors</Form.Label>
                                        <Form.Control as='select' multiple onChange={selectedOptionsChange}>
                                            {navErrors.map((e, i) => {
                                                return <option key={i} value={e}>{e}</option>
                                            })}
                                        </Form.Control>
                                        <Form.Text className="text-muted">Hold CTRL key and click to select multiple items.</Form.Text>

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} className='text-danger mb-3 h4'>TALON Picker</Col>
                                <div className="text-muted">Possible Matches based on Occurence Date/Time and Aircraft Registration Shown Here</div>

                            </Row>
                            <Row>
                                <Col>

                                    <>

                                        {talonFlights &&
                                            <Table
                                                size="sm"
                                                hover
                                                borderless
                                                resonsive="xl"
                                                className=""
                                                id="cadors"
                                            >
                                                <thead className="text-light bg-dark">
                                                    <tr>
                                                        <th>ACTIVITY START</th>
                                                        <th>Instructor</th>
                                                        <th>Student</th>
                                                        <th>Unit</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-light bg-dark">
                                                    {talonFlights.map((flight, index) =>
                                                        <tr onClick={() => handleRowClick(flight.ACTIVITY_ID)}
                                                            key={flight.ACTIVITY_ID}
                                                            className={selected === flight.ACTIVITY_ID ? 'text-success' : ''}

                                                        >
                                                            <td>{flight.ACTIVITY_START}</td>
                                                            <td>{flight.PILOT_IN_COMMAND}</td>
                                                            <td>{flight.STUDENT1}</td>
                                                            <td>{flight.STUDENT1_UNIT}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        }

                                    </>

                                </Col>
                            </Row>




                        </Form>
                    </Card.Body>

                    <Card.Footer>
                        {error && <Alert variant="danger">{error}</Alert>}
                        {success && <Alert variant="success">{success}</Alert>}

                        {!saved &&
                            <>
                                <Button disabled={loading} variant='secondary' size='sm' className="mx-2 my-1" type="submit" onClick={saveCador}>Save</Button>
                                <Button disabled={loading} variant='secondary' size='sm' className="mx-2 my-1" onClick={handleCancel}>Cancel</Button>
                            </>
                        }
                        {saved &&
                            <>
                                <Button disabled={loading} variant='secondary' size='sm' className="mx-2 my-1" type="submit" onClick={handleClose}>Close</Button>

                            </>
                        }

                    </Card.Footer>

                </Card>

            </Modal>
        </div>
    )
}
