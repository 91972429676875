import "./styles.css";

import React from "react";
import { ITypeStatus } from "../UpDownTable";
import { Col, Row } from "react-bootstrap";


export function FleetStatus({ typeStatus }: { typeStatus: ITypeStatus }) {
  return (
    <Row className=""> 
      <Col className="" xs={6}>
        <div className=" h6">{typeStatus.acType}</div>
      </Col>
      <Col className="" xs={3}>
        <div className=" h5">{typeStatus.up}</div>
      </Col>
      <Col className="" xs={3}>
        <div className=" h5">{typeStatus.down}</div>
      </Col>
    </Row>
  );
}
