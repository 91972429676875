import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';
import {NotAuth} from '../Auth/NotAuth'

export default function PrivateRoute({ userRole, component: Component, ...rest }) {
    const { currentUser } = useAuth();
    return (
        
        <Route
            
            {...rest}
            render={(props) => {
                return (
                    <>
                        {!currentUser && <Redirect to="/login" />}

                        {currentUser && 
                            currentUser.claims[userRole] ? 
                            (<Component {...props} />) : 
                            (<NotAuth/>)
                        }
                    </>

                )
            }}
        ></Route>
    );
}
