import React from 'react';
import { Marker } from 'react-map-gl';
import moment from 'moment-timezone';
import FlightDataTag from './FlightDataTag';

const tz = 'America/Moncton'; // Timezone of the Flight Data from the MFC Talon System
//moment.tz.setDefault(tz);
const FlightMarker = ({ flight, viewport, passChildData }) => {
    const overDue = (f) => {
        //Check if a given flight is overdue (Current Time is after ACTIVITY_DUEBACK time from TALON)

        let now = moment().tz(tz);
        let due = moment.tz(
            f.TALON_ACTIVITY_DUEBACK,
            'DD MMM YYYY HH:mm',
            true,
            tz
        );

        let offset = due.diff(now);

        if (offset < 0) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Marker
            //   key={flight.TALON_ACT_ID}
            latitude={flight.latitude}
            longitude={flight.longitude}
            className='marker'
            anchor={'center'}
        >
            <img

                src={overDue(flight) ? `/images/icons/${flight.acType}-red.png` :`/images/icons/${flight.acType}.png` }
                onClick={(e) => {
                    e.preventDefault();
                    passChildData(flight);
                }}
                style={{
                    transform: `rotate(${flight.heading}deg)`,
                    height: `${2.5 * viewport.zoom}px`,
                    width: `${2.5 * viewport.zoom}px`,

                    //fill: overDue(flight) ? 'red' : 'white',
                }}
                alt={"AirplenLogo"}
            />


            {viewport.zoom >= 8 ? ( // This zoom setting controls the zoomm level that needs to be reached for the "flight tag" to be displayed.
                <FlightDataTag flightData={flight} />
            ) : (
                    <div></div>
                )}
        </Marker>
    );
};

export default FlightMarker;
