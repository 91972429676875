import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  Col,
  Row,
  Form,
  Card,
  Alert,
  Button,
  Container,
  Accordion,
} from "react-bootstrap";

import "./adminStyles.css";
import KeyList from "./BiAdmin/KeyList";
import { db } from "../../config/firebase";
import firebase from "firebase";

export function BiAdmin() {
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState();
  const [apiKey, setApiKey] = useState();
  const [email, setEmail] = useState();

  async function saveApiKey() {
    setLoading(true);
    if (!email) {
      setApiKey(null);
      setError("Must supply a valid Email Address");
    }

    try {
      const apiObj = {
        createdAt: new Date().toString(),
        email,
        valid: true,
        key: apiKey.key,
      };

      const docRef = db.collection("BI_API_KEYS").where("email", "==", email);
      const result = await docRef.get();
      console.log(result);
      if (!result.empty) {
        setError("Email Address is already associated with an API Key");
        setLoading(false);
        return;
      }

      await db.collection("BI_API_KEYS").doc(apiKey.key).set(apiObj);
      setLoading(false);
      setSuccess("New API Key Saved");
      setEmail(null);
      setApiKey(null);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }
  function handleEmailChange(e) {
    setSuccess(null);
    setError(null);
    setEmail(e.target.value);
  }

  function generateApiKey() {
    if (!email) {
      setApiKey(null);
      setError("Must supply a valid Email Address");
      setSuccess(null);
      return;
    }
    setError(null);
    const key = uuidv4();

    setApiKey({ key: key, email });
  }

  async function toggleStatus(key) {
    console.log(key);
    try {
      const apiDoc = await db.collection("BI_API_KEYS").doc(`${key}`).get();
      if (!apiDoc.exists) {
        setError("API Key Not found");
        return;
      }
      const obj = apiDoc.data();
      console.log(obj);
      obj.valid = !obj.valid;
      await db.collection("BI_API_KEYS").doc(`${key}`).set(obj);
    } catch (error) {
      setError(error.message);
    }
  }

  async function deleteApiKey(key) {
    console.log(key);
    try {
      await db.collection("BI_API_KEYS").doc(`${key}`).delete();
      setSuccess("API Key Deleted");
    } catch (error) {
      setError(error.message);
    }
  }
  return (
    <>
      <Container className="mt-5 " style={{ maxWidth: "600px" }}>
        <Row className="">
          <Col>
            <h3>BI Admin</h3>
          </Col>
        </Row>
        <Row className="">
          <Col>
            <div className="invite-code-form">
              <Form>
                <Card className="bg-dark">
                  <Card.Header>
                    <div className="text-center h3">API Keys</div>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <div className="h6 text-center">
                        Use this form to generate an API Key for the MFC BI API.
                      </div>

                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        defaultValue={email}
                        onChange={(e) => handleEmailChange(e)}
                      />
                      <Form.Text className="text-muted">
                        <p className="font-small text-secondary"></p>
                        API Keys do not expire. Users do not have to be
                        registered users of the Airboss App in order to access
                        the API.
                      </Form.Text>
                    </Form.Group>
                    {apiKey && (
                      <>
                        <Row>
                          <Col>API KEY</Col>
                        </Row>
                        <Row className="text-center">
                          <Card bg="dark" className="shadow">
                            <Col>{apiKey.key}</Col>
                          </Card>
                        </Row>
                      </>
                    )}
                  </Card.Body>
                  <Card.Footer>
                    {error && (
                      <Alert variant="danger" className="text-center">
                        {error}
                      </Alert>
                    )}
                    {success && (
                      <Alert variant="success" className="text-center">
                        {success}
                      </Alert>
                    )}
                    <Row className="text-center">
                      <Col>
                        <Button
                          variant="secondary"
                          className="shadow"
                          disabled={loading}
                          onClick={generateApiKey}
                        >
                          Generate Key
                        </Button>
                      </Col>
                      {apiKey && (
                        <Col>
                          <Button
                            variant="secondary"
                            className="shadow"
                            disabled={loading}
                            onClick={saveApiKey}
                          >
                            Save Key
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Card.Footer>
                </Card>
              </Form>
            </div>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <KeyList toggleStatus={toggleStatus} deleteApiKey={deleteApiKey} />
          </Col>
        </Row>
      </Container>
    </>
  );
}
