import React, { useState } from "react";
import { useAuth } from "../Contexts/AuthContext";

import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSignOutAlt, faInfo, faUserTie, faUserEdit, faClipboardList, faBars, faChartLine } from "@fortawesome/free-solid-svg-icons";

export default function TopNav() {
  const history = useHistory();
  const [error, setError] = useState("");
  const { logout, currentUser } = useAuth();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch (err) {
      setError(`Failed to logout.  ${error.message}`);
    }
  }
  const fixedNav = (l) => {
    // const location = useLocation();
    if (l === "/map") return "top";
    else return "";
  };
  // console.log(currentUser);

  if (useLocation().pathname === "/map") return <></>;
  return (
    <Navbar className="navbar-default text-center" collapseOnSelect="true" expand="lg" variant="dark" bg={""}>
      <NavDropdown
        title={
          <div style={{ display: "inline-block" }}>
            <FontAwesomeIcon icon={faBars} />
          </div>
        }
        id="collapsible-nav-dropdown"
        className=""
      >
        {currentUser.claims.opsMgmt && (
          <NavDropdown.Item as={NavLink} to="/cador" href="/cador" activeClassName="" className="dropDownLinks ml-auto">
            <FontAwesomeIcon icon={faClipboardList} />
            <span className="mx-2">CADORs</span>
          </NavDropdown.Item>
        )}
        <NavDropdown.Divider />
        <NavDropdown.Item as={NavLink} to="/profile" href="/profile" activeClassName="" className="dropDownLinks ml-auto">
          <FontAwesomeIcon icon={faUserEdit} />
          <span className="mx-2">Profile</span>
        </NavDropdown.Item>
        {currentUser.claims.admin && ( //Hide link for non-admin users
          <>
            <NavDropdown.Item as={NavLink} to="/admin" href="/admin" activeClassName="" className="dropDownLinks ml-auto">
              <FontAwesomeIcon icon={faUserTie} />
              <span className="mx-2">Admin</span>
            </NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/bi-admin" href="/bi-admin" activeClassName="" className="dropDownLinks ml-auto">
              <FontAwesomeIcon icon={faChartLine} />
              <span className="mx-2">BI API</span>
            </NavDropdown.Item>
          </>
        )}
        <NavDropdown.Divider />
        <NavDropdown.Item as={NavLink} to="/appabout" href="/appabout" activeClassName="" className="dropDownLinks ml-auto">
          <FontAwesomeIcon icon={faInfo} />
          <span className="mx-2">AirBoss Info</span>
        </NavDropdown.Item>
        <NavDropdown.Item as={NavLink} to="" href="" activeClassName="" onClick={handleLogout} className="dropDownLinks">
          <FontAwesomeIcon icon={faSignOutAlt} />
          <span className="mx-2">Logout</span>
        </NavDropdown.Item>
      </NavDropdown>
      <Nav.Link className="ml-auto" to="/">
        <img src="images/mfcLogo_white.png" width="100" alt="logo" />
      </Nav.Link>
    </Navbar>
  );
}
