import React from "react";
import {
  LineChart,
  Line,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { Card } from "react-bootstrap";

import moment from "moment-timezone";

export const LineCharts = ({ acStatus, base }) => {
  const colors = [
    "#114CD3",
    "#C139BA",
    "#FF468E",
    "#FF8065",
    "#FFBF52",
    "#F9F871",
  ];

  if (!acStatus[0][base]) {
    return (
      <Card bg="dark">
        <Card.Header>
          <Card.Title>{base} Availability</Card.Title>
        </Card.Header>
        <Card.Body>
          <h6>No Data Found</h6>
        </Card.Body>
      </Card>
    );
  }
  const data = [];
  let keys = Object.keys(acStatus[0][base].UP);

  acStatus.forEach((item) => {
    let cartObject = {
      time: new moment(item.createdAt).format("ddd HH:mm").toString(),
    };
    for (let i = 0; i < keys.length; i++) {
      cartObject[keys[i]] = item[base].UP[keys[i]];
    }
    data.push(cartObject);
  });

  const colorPicker = (type) => {
    switch (type) {
      case "DA20-C1":
        return "#114CD3";
      case "C172":
        return "#C139BA";
      case "C172S":
        return "#FF468E";
      case "PA44-180":
        return "#FF8065";
      case "C90":
        return "#FFBF52";
    }
  };
  // const acData = [
  //     { time: 'time', up: totalUP}
  // ]
  const renderLines = () => {
    const lines = keys.map((value, index) => (
      <Line
        key={value}
        name={value}
        type="monotone"
        dataKey={value}
        stroke={colorPicker(value)}
        strokeWidth={2}
        dot={false}
      />
    ));
    return lines;
  };
  return (
    <Card bg="dark">
      <Card.Header>
        <Card.Title>{base} Availability</Card.Title>
      </Card.Header>
      <Card.Body>
        <ResponsiveContainer className="lineChart" width={"99%"} height={300}>
          <LineChart classNamewidth={400} height={400} data={data}>
            {renderLines()}
            <XAxis dataKey="time" />
            <YAxis dataKey="DA20-C1" />
            <Tooltip contentStyle={{ backgroundColor: "#343a40" }} />
            <Legend verticalAlign="top" height={10} />
          </LineChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
};
