import axios from "axios";
import moment from "moment-timezone";
import { auth } from "../config/firebase";

const tz = "America/Moncton";

const fidServer = process.env.REACT_APP_AIRBOSS_URL;

export const getInstructorId = async (talonActId) => {
  const token = await auth.currentUser.getIdToken();
  let url = process.env.REACT_APP_AIRBOSS_URL || "http://localhost:5000/api";

  if (talonActId) {
    return axios
      .get(`${url}/talon/instructorId/${talonActId}`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        if (err.isAxiosError) {
          return err.response.data;
        }
        return err;
      });
  }
  return;
};

export const getAircraftInfo = async () => {
  try {
    let { data } = await axios.get(
      `https://provincial.elietech.ca/web/api/talon/aircraftstatus`,
      {
        auth: {
          username: "mfcuser",
          password: "mfcuser",
        },
      }
    );
    data = data.filter((d) => d.OBSOLETE === "0");
    return data;
  } catch (error) {
    console.log("getAircraftInfo Error: ", error);
    return error;
  }
};

export const getMetar = async () => {
  let metarCYQM = await axios.get(
    `https://provincial.elietech.ca/web/api/metars/cyqm?latest=true`,
    {
      auth: {
        username: "mfcuser",
        password: "mfcuser",
      },
    }
  );

  let metarCYFC = await axios.get(
    `https://provincial.elietech.ca/web/api/metars/cyfc?latest=true`,
    {
      auth: {
        username: "mfcuser",
        password: "mfcuser",
      },
    }
  );

  let metarCYRT = await axios.get(
    `https://provincial.elietech.ca/web/api/metars/cyrt?latest=true`,
    {
      auth: {
        username: "mfcuser",
        password: "mfcuser",
      },
    }
  );

  let metarCYTH = await axios.get(
    `https://provincial.elietech.ca/web/api/metars/cyth?latest=true`,
    {
      auth: {
        username: "mfcuser",
        password: "mfcuser",
      },
    }
  );

  let metars = {
    CYFC: metarCYFC.data,
    CYQM: metarCYQM.data,
    CYRT: metarCYRT.data,
    CYTH: metarCYTH.data,
  };

  return metars;
};

export const getiids = async (airportId) => {
  try {
    const { data } = await axios.get(`${fidServer}/navcan/iids/${airportId}`);
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getTalonFlights = async (timeStamp) => {
  let startDate, startTime, endDate, endTime, end;

  if (!timeStamp) {
    var start = moment(new Date()).tz(tz).subtract(12, "hours");
    end = moment(new Date()).tz(tz).add(12, "hours");
    startDate = start.format("DD MMM YYYY").toString();
    startTime = start.format("HH:mm").toString();
    endDate = end.format("DD MMM YYYY").toString();
    endTime = end.format("HH:mm").toString();
  } else {
    startDate = moment(timeStamp)
      .subtract(4, "hours")
      .tz(tz)
      .format("DD MMM YYYY")
      .toString();
    startTime = moment(timeStamp)
      .subtract(4, "hours")
      .tz(tz)
      .format("HH:mm")
      .toString();
    endDate = moment(timeStamp)
      .add(1, "hours")
      .tz(tz)
      .format("DD MMM YYYY")
      .toString();
    endTime = moment(timeStamp)
      .add(1, "hours")
      .tz(tz)
      .format("HH:mm")
      .toString();
  }

  try {
    const { data } = await axios.get(
      `https://provincial.elietech.ca/web/api/talon/operations/?startDate=${startDate}&startTime=${startTime}&endDate=${endDate}&endTime=${endTime}`,
      {
        auth: {
          username: process.env.REACT_APP_mfcTalonUser,
          password: process.env.REACT_APP_mfcTalonPass,
        },
      }
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const getCxFlight = (flightId) => {
  try {
    return axios
      .get(
        `https://provincial.elietech.ca/web/api/talon/cxanal/id/${flightId}`,
        {
          auth: {
            username: process.env.REACT_APP_mfcTalonUser,
            password: process.env.REACT_APP_mfcTalonPass,
          },
        }
      )
      .then((res) => {
        return res.data[0];
      });
    //return data[0].ACT_CMNT
  } catch (err) {
    console.log(err);
  }
};

export const getAddsWx = async (boundary) => {
  //get adds weather metars for a given box defined from MapBox viewport
  //return array of Stations, WxCategory, and RAW Metar

  if (!boundary) return;

  const xml2js = require("xml2js");
  let parser = new xml2js.Parser({ explicitArray: false, attrkey: "@" });
  const token = await auth.currentUser.getIdToken();
  let proxyUrl = escape(
    `https://www.aviationweather.gov/adds/dataserver_current/httpparam?dataSource=metars&requestType=retrieve&format=xml&minLat=${boundary.min.lat}&minLon=${boundary.min.long}&maxLat=${boundary.max.lat}&maxLon=${boundary.max.long}&hoursBeforeNow=3&mostRecentForEachStation=constraint`
  );

  try {
    let { data } = await axios.get(`${fidServer}/proxy?url=${proxyUrl}`, {
      headers: { authorization: `Bearer ${token}` },
    });

    let parsedData = await parser.parseStringPromise(data);
    let metars = parsedData.response.data.METAR;

    if (Array.isArray(metars) === false) {
      //If only a single METAR returned, push it into an array
      let metarArray = [];
      metarArray.push(metars);
      return metarArray.filter((metar) => metar.flight_category);
    }
    return metars.filter((metar) => metar.flight_category);
  } catch (error) {
    console.log(error);
  }
};
