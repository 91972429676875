import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { getCadorsforTable } from '../../api/cadorAPI'
import moment from 'moment-timezone';

const tz = 'America/Moncton'

export const CadorTable = ({ cadorDetailsToParent }) => {
    const [retrieve, setRetrieve] = useState(10)
    const [cadors, setCadors] = useState(null);
    const [keys, setKeys] = useState(null)



    useEffect(() => {
        (async () => {
            getCadorsforTable(retrieve).then(response => {
                setCadors(response.data);
            });

        })()
        return () => {
            setCadors({});
        };;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (cadors) {
           setKeys(Object.keys(cadors[0]).filter(k => k === 'cadorNumber' || k === 'occurrenceDate' || k === 'updatedAt' || k==='lastModifiedBy'))
           let c = [...cadors]
           c.forEach(cador => {
               cador.updatedAt = moment(cador.updatedAt).tz(tz).format('DD-MMM-YYYY  HH:mm')
           })
        }
    }, [cadors])


    const headings = {
        cadorNumber: 'CADOR #',
        occurrenceDate: 'CADOR Date',
        updatedAt: 'Last Modified',
        lastModifiedBy: 'Modified By'

    };

    const handleClick = (cador) => {
        cadorDetailsToParent(cador.cadorNumber)


    }
    return (
        <div>
            {(keys && cadors) &&
                <Table
                    size="lg"
                    hover
                    borderless
                    resonsive="xl"
                    className=""
                    id="cadors"

                >
                    <thead className="text-light bg-dark">
                        <tr>
                            {keys.map((item, index) =>

                                <th key={index}>{headings[item]}</th>

                            )}


                        </tr>
                    </thead>
                    <tbody 
                    
                    className="text-light bg-dark">
                        {cadors.map((cador, index) => (
                            <tr
                                key={index}
                                onClick={() => handleClick(cador)}
                                
                            >
                                {keys.map((item, i) =>

                                    <td key={item._id}>
                                        {cador[item] ? cador[item] : '-'}

                                    </td>

                                )}

                            </tr>
                        ))}
                    </tbody>

                </Table>
            }
        </div>
    )
}
