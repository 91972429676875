import React, { useState, useEffect } from "react";
import axios from "axios";

import { Container, Row, Col, Button, Modal, Card } from "react-bootstrap";
import "./maintenance.css";
import moment from "moment-timezone";
import { UpDownTable } from "./UpDownTable";
import { LineCharts } from "../Charts/LineChart/LineCharts";
import { useAuth } from "../Contexts/AuthContext";
import { useFlights } from "../Contexts/FlightContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IAircraftInfo } from "../../interfaces/IAircraft";
import { MaintStatus } from "./FleetStatus";
import { bases } from "../../config/bases";
export const Maintenance = () => {
  const { getToken } = useAuth();
  const { aircraftInfo }: { aircraftInfo: IAircraftInfo } = useFlights();
  const [acStatus, setAcStatus] = useState<any>();
  const [currentAcStatus, setCurrentAcStatus] = useState();
  const [lastUpdated, setLastUpdated] = useState<any>();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [newStartDate, setNewStartDate] = useState<any>(startDate);
  const [newEndDate, setNewEndDate] = useState<any>(endDate);
  const [modalOptions, setModalOptions] = useState({
    show: false,
  });

  const getAcStatus = async (start = new Date(), end = new Date()) => {
    const token = await getToken();

    let url = process.env.REACT_APP_AIRBOSS_URL;

    return axios
      .get(
        `${url}/talon/acstatus/?startDate=${start.toISOString()}&endDate=${end.toISOString()}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )
      .then((res) => res.data);
  };

  const handleSettingsClick = () => {
    setModalOptions({
      show: true,
    });
  };

  const handleCloseSettingsModal = () => {
    setModalOptions({
      show: false,
    });
  };

  const handleSaveSettingsModal = () => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    getAcStatus(startDate, endDate).then((data) => {
      setAcStatus(data);
    });
    handleCloseSettingsModal();
  };

  useEffect(() => {
    setStartDate(newStartDate);
  }, [newStartDate]);

  useEffect(() => {
    setEndDate(newEndDate);
  }, [newEndDate]);
  //Inital Load of AC Status Data on Component load
  useEffect(() => {
    (async () => {
      getAcStatus(startDate, endDate).then((data) => {
        setAcStatus(data);
      });
      getAcStatus().then((data) => {
        setCurrentAcStatus(data);
      });
    })();
    return () => {
      setAcStatus({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Set "last Updated Time"
  useEffect(() => {
    if (!acStatus) return;
    let index = acStatus.length - 1;
    const date = moment(acStatus[index].updatedAt).fromNow();
    setLastUpdated(date);
  }, [acStatus]);

  //Refresh AC Status every 2 minute
  useEffect(() => {
    const main = async () => {
      getAcStatus(startDate, endDate).then((data) => {
        setCurrentAcStatus(data);
        let index = data.length - 1;
        const date = moment(data[index].updatedAt).fromNow();
        setLastUpdated(date);
      });
    };

    const interval = setInterval(() => main(), 1000 * 120);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col className="display-4 dash-col justify-content-md-center">
          Current Fleet Status
        </Col>
      </Row>
      <Row className="justify-content-sm-center">
        {bases.map((base, index) => {
          return (
            <Col
              key={index}
              className="dash-col display-4 justify-content-md-center"
              sm={12}
              md={3}
            >
              {currentAcStatus && (
                <>
                  <UpDownTable currentAcStatus={currentAcStatus} base={base} />
                </>
              )}
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col className="display-4 dash-col justify-content-md-center"></Col>
      </Row>
      <Row className="justify-content-sm-center">
        {bases.map((base, index) => {
          return (
            <Col
              key={index}
              sm={12}
              md={6}
              className="dash-col justify-content-md-center"
            >
              <div>
                {acStatus && <LineCharts acStatus={acStatus} base={base} />}
              </div>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col className="dash-col justify-content-md-center">
          <Button
            size="sm"
            className="btn-secondary mx-2 my-1"
            onClick={handleSettingsClick}
          >
            Chart Settings
          </Button>
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <MaintStatus status={acStatus} />
        </Col>
      </Row> */}

      <Row>
        <Col className="dash-col justify-content-md-center">
          <div className="text-muted">Last Updated {lastUpdated}</div>
        </Col>
      </Row>
      <Modal
        size="lg"
        //dialogClassName="modalStyle"
        animation={true}
        show={modalOptions.show}
        onHide={handleCloseSettingsModal}
        align-items-center="true"
        justify-content-center="true"
        className="mt-3 mb-3"
      >
        <Card className="bg-dark">
          <Card.Header>
            <Card.Title>Chart Date Range</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col
                sm={12}
                md={6}
                className="dash-col justify-content-md-center"
              >
                <div className="mb-1">Start Date</div>
                <DatePicker
                  selected={newStartDate}
                  onChange={(date) => setNewStartDate(date)}
                />
              </Col>
              <Col
                sm={12}
                md={6}
                className="dash-col justify-content-md-center"
              >
                <div className="mb-1">End Date</div>
                <DatePicker
                  selected={newEndDate}
                  onChange={(date) => setNewEndDate(date)}
                />
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <Button
              size="sm"
              className="btn-secondary mx-2 my-1"
              onClick={handleSaveSettingsModal}
            >
              Save & Close
            </Button>
            <Button
              size="sm"
              className="btn-secondary mx-2 my-1"
              onClick={handleCloseSettingsModal}
            >
              Cancel
            </Button>
          </Card.Footer>
        </Card>
      </Modal>
    </Container>
  );
};
