import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./styles.css";

function handleClick(props) {
  console.log(props.radarVisibility);
  if (props.radarVisibility === "visible") {
    props.setRadarVisibility("none");
  }

  if (props.radarVisibility === "none") {
    props.setRadarVisibility("visible");
  }
}

export function RadarToggle(props) {
  return (
    <div>
      <FontAwesomeIcon icon={faLayerGroup} size="lg" className="layerToggle" enableBackground={"dark"} onClick={() => handleClick(props)} />
    </div>
  );
}
