import React, { useState } from 'react';
import {Badge} from 'react-bootstrap'
import { Marker } from 'react-map-gl';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDotCircle
} from '@fortawesome/free-regular-svg-icons';


const flightCategory = (metar) => {

    if (metar.flight_category === 'VFR') return '#31e32b'
    if (metar.flight_category === 'MVFR') return '#0026ff'
    if (metar.flight_category === 'IFR') return '#ff0000'
    if (metar.flight_category === 'LIFR') return '#ea00ff'
}
const tz = 'America/Moncton'; // Timezone of the Flight Data from the MFC Talon System
//moment.tz.setDefault(tz);
const WxMarker = ({ wx, viewport, passChildData }) => {
    const [marker, setMarker] = useState();

    

    return (
        <Marker
            latitude={parseFloat(wx.latitude)}
            longitude={parseFloat(wx.longitude)}
            className='marker wxMarker'
            anchor={'center'}
            id={wx.station_id}
            offsetLeft={viewport.zoom}
            offsetTop={viewport.zoom*-2}
            




        >
            {/* <img
                onClick={(e) => {
                    e.preventDefault();
                    passChildData(wx);
                }}
                src='/images/icons/marker.png'
                style={{
                    height: `${2.5 * viewport.zoom}px`,
                    width: `${2.5 * viewport.zoom}px`
                }}
                alt={'marker'}
            /> */}
            <FontAwesomeIcon
            icon={faDotCircle}
            color={flightCategory(wx)}
            onClick={(e) => {
                    e.preventDefault();
                    passChildData(wx);
                }}
                style={{
                    height: `${2.0 * viewport.zoom}px`,
                    width: `${2.0 * viewport.zoom}px`
                }}

            />
        </Marker>



    );
};

export default WxMarker;
