import axios from "axios";
import { auth } from "../config/firebase";

let baseUrl = process.env.REACT_APP_AIRBOSS_URL || "http://localhost:5000/api";

const cameraUrl = baseUrl + "/cameras";

export async function getCameras() {
  const token = await auth.currentUser.getIdToken();
  return axios(cameraUrl, {
    headers: { authorization: `Bearer ${token}` },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}
