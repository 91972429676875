import {Modal, Container, Row, Col, Button } from 'react-bootstrap'
import React from 'react';
import moment from 'moment-timezone';

export function FlightDetails({ flight, modalOptions, closeFlightDetails }) {


    return (
        <>
            <Modal
            size='lg'
            centered
            animation={true}
            dialogClassName="modalStyle"
            show={modalOptions.show}
            onHide={() => closeFlightDetails()}
            >
            <div
                    className="text-light bg-dark text-center"
                    
                >
                    <Container fluid>
                        <Row className="modalHeader display-3">
                            <Col>Flight Details</Col>
                        </Row>
                        <Row>
                            <Col md={4} className="gridCol my-auto">
                                <Row className="pb-2">
                                    A/C:
                                    <span className="ml-auto">
                                        {flight.RESOURCE_NAME && flight.RESOURCE_NAME}
                                    </span>
                                </Row>
                                <Row className="pb-2">
                                    Start:
                                    <span className="ml-auto">
                                        {flight.ACTIVITY_START.format(
                                            'HH:mm'
                                        ).toString()}
                                    </span>
                                </Row>
                                <Row className="pb-2">
                                    Off:<span className="ml-auto">--</span>
                                </Row>
                                <Row className="pb-2">
                                    Status:
                                    <span className="ml-auto">{flight.STATUS}</span>
                                </Row>
                                {(flight.STATUS === 'Cancelled' && flight.cxReason) && 
                                    <Row className="pb-2">
                                        CX Reason:
                                        <span className="ml-auto">{flight.cxReason}</span>
                                    </Row>
                                }
                                
                            </Col>
                            <Col md={2}></Col>

                            <Col md={4} className="gridCol my-auto">
                                <Row className="pb-2">
                                    Destination:
                                    <span className="ml-auto">
                                        {flight.DESTINATION
                                            ? flight.DESTINATION
                                            : 'Unknown'}
                                    </span>
                                </Row>
                                <Row className="pb-2">
                                    Due:
                                    <span className="ml-auto">
                                        {flight.ACTIVITY_DUEBACK.format(
                                            'HH:mm'
                                        ).toString()}
                                    </span>
                                </Row>
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col className="modalHeader display-3">
                                Crew Info
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} className="gridCol my-auto">
                                <Row className="pb-2">
                                    Student:
                                    <span className="ml-auto">
                                        {flight.STUD1_LNAME +
                                            ', ' +
                                            flight.STUD1_FNAME.slice(0, 1)}
                                    </span>
                                </Row>
                                <Row className="pb-2">
                                    Lesson:
                                    <span className="ml-auto">
                                        {flight.STUDENT1_UNIT}
                                    </span>
                                </Row>
                                <Row className="pb-2">
                                    FlightID:
                                    <span className="ml-auto">
                                        {flight.ACTIVITY_ID}
                                    </span>
                                </Row>
                            </Col>
                            <Col md={2}></Col>

                            <Col md={4} className="gridCol my-auto">
                                <Row className="pb-2">
                                    Course:
                                    <span className="ml-auto">
                                        {flight.STUDENT1_COURSE}
                                    </span>
                                </Row>
                                <Row className="pb-2">
                                    Instructor:{' '}
                                    <span className="ml-auto">
                                        {flight.PILOT_IN_COMMAND
                                            ? flight.PIC_LNAME +
                                              ', ' +
                                              flight.PIC_FNAME.slice(0, 1)
                                            : '--'}
                                    </span>
                                </Row>
                                <Row className="pb-2">
                                    Data: <span className="ml-auto"></span>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="gridCol">
                                <h6>
                                    Flight Status updated{' '}
                                    {moment(flight.statusDateTime).fromNow()}.
                                </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={() => closeFlightDetails()}>
                                    Close
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <p></p>
                        </Row>
                    </Container>
                </div>
            </Modal>
        </>

    )
}
