import { Alert, Form, Button, Card, Modal, Row, Col } from 'react-bootstrap'
import { useAuth } from '../Contexts/AuthContext';

import React, { useState, useRef } from 'react';

export function UserDetails({ user, modalOptions, closeUserDetails }) {
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const [validPass, setValidPass] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [modUser, setModUser] = useState({
        details:
        {
            email: user.email,
            emailVerfified: true,
            displayName: user.displayName,
            phoneNumber: user.phoneNumber
        },
        customClaims:
        {
            admin: user.admin,
            general: true,
            flightOps: user.flightOps,
            opsMgmt: user.opsMgmt,
            maintenance: user.maintenance,
            instructor: user.instructor,
            student: user.student,
        }
    });


    const { updateUser, deleteUser } = useAuth();




    const handleFormSubmit = async (e) => {
        e.preventDefault()
        let uid = user.uid


        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match')
        }

        if (passwordRef.current.value && passwordRef.current.value === passwordConfirmRef.current.value) {
            let u = { ...modUser }
            u.details.password = passwordRef.current.value
            setModUser(u)
        }

        try {
            setError('');
            setLoading(true);
            await updateUser(uid, modUser)
            setSuccess(true);

        } catch (err) {
            if (err.response.data.message) {
                setError(`User Modification Failed. ${err.response.data.message}`)
            } else setError(`User Modification Failed. ${err.message}`);

        }
        setLoading(false);
        setValidPass(false);

    }

    const handleDeleteUser = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);
        await deleteUser(user.uid)
        setSuccess(true);

        try {

        } catch (err) {
            setError(`User Modification Failed. ${err.message}`)
        }
        setLoading(false)
        setConfirmDelete(false)
    }
    return (
        <>
            {/* User Details Modal */}
            <Modal
                size='xl'
                dialogClassName="modalStyle"
                animation={true}
                show={modalOptions.show}
                onHide={() => closeUserDetails()}
            >
                <div className="text-light bg-dark text-center">

                    <Card className="bg-dark">

                        <Card.Body>
                            {error && <Alert variant="danger">{error}</Alert>}


                            <Form>
                                <Row>
                                    <Col>
                                        <div className="h5 mb-4">Change User Details</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={4}>
                                        <Form.Group>
                                            <Form.Label>Display Name</Form.Label>
                                            <Form.Control type="text" defaultValue={user.displayName || null} onChange={(d) => {
                                                let u = { ...modUser };
                                                u.details.displayName = d.target.value
                                                setModUser(u)
                                            }
                                            }>

                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group>
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control type="email" defaultValue={user.email} onChange={(d) => {
                                                let u = { ...modUser };
                                                u.details.email = d.target.value
                                                setModUser(u)
                                            }}>

                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={4}>
                                        <Form.Group>
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control type="tel" defaultValue={user.phoneNumber} onChange={(d) => {
                                                let u = { ...modUser };
                                                d.target.value.length === 0 ? u.details.phoneNumber = null : u.details.phoneNumber = d.target.value
                                                
                                                setModUser(u)
                                            }}>
                                            </Form.Control>
                                            <Form.Label>
                                                <div className="text-muted">Mobile number required for multifactor authentication.</div>
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <div className="h5 mt-5 mb-4">Change Password</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Form.Label>New Pasword</Form.Label>
                                        <Form.Control type="password" placeholder={'Leave blank to keep the same'} ref={passwordRef} />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Form.Label>Confirm Pasword</Form.Label>
                                        <Form.Control type="password" placeholder={'Leave blank to keep the same'} ref={passwordConfirmRef} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-4 mb-1" sm={12}>
                                        <div className='h5'>Give user ADMIN privileges</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Form.Group controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" checked={modUser.customClaims.admin} label="Admin" onChange={(d) => {
                                                let u = { ...modUser };
                                                u.customClaims.admin = !modUser.customClaims.admin
                                                setModUser(u)
                                            }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-1" sm={12}>
                                        <div className='text-muted'>Admin privileges are required to be able to add/remove other users.</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-4 mb-1" sm={12}>
                                        <div className='h5'>Select User Access Levels</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={2}>
                                        <Form.Group controlId="generalCheckBox">
                                            <Form.Check type="checkbox" label="General" defaultChecked disabled={true} readOnly={true} />

                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={2}>
                                        <Form.Group controlId="fltOpsCheckBox">
                                            <Form.Check type="checkbox" checked={modUser.customClaims.flightOps} label="Flight Ops" onChange={(d) => {
                                                let u = { ...modUser };
                                                u.customClaims.flightOps = !modUser.customClaims.flightOps
                                                setModUser(u)
                                            }} />
                                        </Form.Group>
                                    </Col>

                                    <Col sm={12} md={2}>
                                        <Form.Group controlId="instructorCheckBox">
                                            <Form.Check type="checkbox" checked={modUser.customClaims.instructor} label="Instructor" onChange={(d) => {
                                                let u = { ...modUser };
                                                u.customClaims.instructor = !modUser.customClaims.instructor
                                                setModUser(u)
                                            }} />
                                        </Form.Group>
                                    </Col>

                                    <Col sm={12} md={2}>
                                        <Form.Group controlId="studentCheckBox">
                                            <Form.Check type="checkbox" checked={modUser.customClaims.student} label="Student" onChange={(d) => {
                                                let u = { ...modUser };
                                                u.customClaims.student = !modUser.customClaims.student
                                                setModUser(u)
                                            }} />
                                        </Form.Group>
                                    </Col>

                                    <Col sm={12} md={2}>
                                        <Form.Group controlId="maintCheckBox">
                                            <Form.Check type="checkbox" checked={modUser.customClaims.maintenance} label="Maintenance" onChange={(d) => {
                                                let u = { ...modUser };
                                                u.customClaims.maintenance = !modUser.customClaims.maintenance
                                                setModUser(u)
                                            }} />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={2}>
                                        <Form.Group controlId="fltOpsMgmtCheckBox">
                                            <Form.Check type="checkbox" checked={modUser.customClaims.opsMgmt} label="Flt Ops Management" onChange={(d) => {
                                                let u = { ...modUser };
                                                u.customClaims.opsMgmt = !modUser.customClaims.opsMgmt
                                                setModUser(u)
                                            }} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-1" sm={12}>
                                        <div className='text-muted'>Access levels control the areas that the user is able to access.  Select all that are applicable.  The "General" user level is applicable to all users.</div>
                                    </Col>
                                </Row>



                            </Form>
                            <Row className="mt-3">
                                <Col>
                                    {success &&

                                        <>
                                            <Alert variant="success">User Modified</Alert>
                                            <Button variant='secondary' size='sm' className="mx-2 my-1" onClick={closeUserDetails}>Close</Button>
                                        </>
                                    }
                                    {!success &&
                                        <>

                                            <Button disabled={loading} variant='secondary' size='sm' className="mx-2 my-1" type="submit" onClick={handleFormSubmit}>Modify User</Button>
                                            <Button disabled={loading} variant='secondary' size='sm' className="mx-2 my-1" onClick={closeUserDetails}>Cancel</Button>




                                        </>
                                    }
                                </Col>


                            </Row>
                        </Card.Body>
                        <Card.Footer>

                            <Row className="mt-3 mb-2">
                                <Col>
                                    💀 Danger Zone 💀
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {!confirmDelete &&
                                        <Button disabled={loading} variant='outline-danger' size='sm' className="mx-2 my-1" onClick={() => setConfirmDelete(true)}>Delete User</Button>

                                    }
                                    {confirmDelete &&
                                        <>
                                            <Button disabled={loading} variant='danger' size='sm' className="mx-2 my-1" onClick={handleDeleteUser}>Yes, Delete.</Button>
                                            <Button disabled={loading} variant='success' size='sm' className="mx-2 my-1" onClick={() => setConfirmDelete(false)}>Nope...Cancel!</Button>

                                        </>

                                    }
                                    <div className='text-muted'>This action cannot be undone.</div>
                                </Col>
                            </Row>




                        </Card.Footer>
                    </Card>

                </div>
            </Modal>
        </>

    )
}
