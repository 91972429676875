import React, { useState, useEffect, useCallback } from "react";
import { useInterval } from "../hooks/useInterval";
import ReactMapGL, { Popup, FullscreenControl } from "react-map-gl";

import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import "mapbox-gl/dist/mapbox-gl.css";
import "./map.css";

import FlightMarker from "../components/Map/FlightMarker";
import FlightPopup from "../components/Map/FlightPopup";

import { airplaneTypes } from "../config/airplaneTypes";
import { useFlights } from "../components/Contexts/FlightContext";
import { getAddsWx } from "../api/api";
import { debounce } from "lodash";
import WxMarker from "../components/Map/WxMarker";
import WxMarkerPopup from "../components/Map/WxMarkerPopup";

import { cameraList } from "../config/cameraList";
import { CameraMarker } from "../components/Map/CameraMarker";
import { CameraMarkerPopup } from "../components/Map/CameraMarkerPopup";
import { getCameras } from "../api/camerasAPI";
import { RadarSource } from "../components/Map/RadarSource";
import { LayerToggle, RadarToggle } from "../components/Map/LayerToggle/RadarToggle";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const METAR_REFRESH_INTERVAL = 1000 * 60 * 2;
const METAR_DEBOUNCE_INTERVAL = 1500;

export default function App({ mapWidth, mapHeight, lat, long, zoom }) {
  let initialViewportState = {
    width: mapWidth || "100vw",
    height: mapHeight || "95.5vh",
    latitude: lat || 45.967,
    longitude: long || -65.67,
    zoom: zoom || 7.5,
  };
  //  /4558N06540W

  const [radarVisibility, setRadarVisibility] = useState("visible");
  const { airborneFlights } = useFlights();
  const [viewport, setViewport] = useState(initialViewportState);
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [cameras, setCameras] = useState(null);
  const [selectedWx, setSelectedWx] = useState(null);
  const [selectedCam, setSelectedCam] = useState(null);
  const [flights, setFlights] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [map, setMap] = useState();
  const [metars, setMetars] = useState();
  const [mapBounds, setMapBounds] = useState();

  useEffect(() => {}, [metars]);

  useEffect(() => {
    getCameras().then((response) => setCameras(response));
  }, []);
  useEffect(() => {
    console.log("Cameras", cameras);
  }, [cameras]);

  const metarBounceHandler = useCallback(
    debounce((bnds) => {
      //Keep ADDS API Calls to 1 every 3 seconds
      getAddsWx(bnds).then((data) => setMetars(data));
    }, METAR_DEBOUNCE_INTERVAL),
    []
  );

  useEffect(() => {
    //Download New Metars whenever the Map Viewport Changes
    metarBounceHandler(mapBounds);
  }, [mapBounds]);

  useInterval(() => {
    getAddsWx(mapBounds).then((data) => setMetars(data));
  }, METAR_REFRESH_INTERVAL);

  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setSelectedFlight(null);
        setSelectedFeature(null);
      }
    };
    window.addEventListener("keydown", listener);
  }, []);

  //Initial FLight Load on Page Load
  useEffect(() => {
    if (airborneFlights.length > 0) {
      updateFlights();
    }
  }, [airborneFlights]);

  useEffect(() => {
    const handleResize = () => {
      setViewport({
        ...viewport,
        width: "100vw",
        height: mapHeight || "95.5vh",
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const getAge = (timeStamp) => {
    let startDate = new Date(timeStamp);
    let now = new Date();
    return (now.getTime() - startDate.getTime()) / 1000;
  };

  const updateFlights = () => {
    let flightDataUpdate = [];
    let fltData = airborneFlights;

    fltData.forEach((flt) => {
      let index = flt.positions.length - 1;
      let currentPosition = flt.positions[index];

      let flightInfo = {
        faFlightID: flt.faFlightID,
        TALON_ACT_ID: flt.TALON_ACT_ID,
        ident: flt.ident,
        acType: airplaneTypes[flt.ident],
        type: flt.type,
        origin: flt.origin,
        destination: flt.destination,
        longitude: currentPosition.longitude,
        latitude: currentPosition.latitude,
        altitude: currentPosition.altitude,
        heading: currentPosition.heading,
        groundspeed: currentPosition.groundspeed,
        altitudeStatus: currentPosition.altitudeStatus,
        altitudeChange: currentPosition.altitudeChange,
        positionAge: getAge(currentPosition.timestamp),
        TALON_PIC: flt.TALON_PIC,
        TALON_STUDENT1: flt.TALON_STUDENT1,
        TALON_STUDENT2: flt.TALON_STUDENT2,
        TALON_STUDENT1_UNIT: flt.TALON_STUDENT1_UNIT,
        TALON_STUDENT2_UNIT: flt.TALON_STUDENT2_UNIT,
        TALON_DESTINATION: flt.TALON_DESTINATION,
        TALON_ACTIVITY_DUEBACK: flt.TALON_ACTIVITY_DUEBACK,
        TALON_ROUTE: flt.TALON_ACTIVITY_ROUTE,
      };

      flightDataUpdate.push(flightInfo);
    });
    setFlights(flightDataUpdate);
  };

  const handleClick = (e) => {
    let featureName = null;
    e.preventDefault();

    if (e.features.length > 0 && e.features[0].sourceLayer === "MFCTrainingAreas_revised") {
      featureName = e.features[0].properties.name;
    } else featureName = null;

    let clickLocation = e.lngLat;

    let feature = {
      featureName,
      clickLocation,
    };

    if (featureName) setSelectedFeature(feature);
  };

  const handleLoad = () => {
    //Content executed when MAP is Loaded
    if (!map.getBounds()) return;
    setMapBounds({
      max: {
        lat: map.getBounds()._ne.lat,
        long: map.getBounds()._ne.lng,
      },

      min: {
        lat: map.getBounds()._sw.lat,
        long: map.getBounds()._sw.lng,
      },
    });
    // getAddsWx(mapBounds).then(data => setMetars(data));
  };

  const handleViewportChange = () => {
    //Set the new map boundaries

    setMapBounds({
      max: {
        lat: map.getBounds()._ne.lat,
        long: map.getBounds()._ne.lng,
      },

      min: {
        lat: map.getBounds()._sw.lat,
        long: map.getBounds()._sw.lng,
      },
    });
  };

  return (
    <div className="mapDiv">
      <ReactMapGL
        {...viewport}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapStyle="mapbox://styles/wstock/ckh50hxk000bo19kal9254epb"
        onViewportChange={(viewport) => {
          setViewport(viewport);

          if (map) {
            handleViewportChange(map);
          }
        }}
        on
        dragRotate={false}
        onClick={(e) => handleClick(e)}
        attributionControl={false}
        ref={(ref) => {
          ref && setMap(ref.getMap());
        }}
        onLoad={handleLoad}
      >
        <RadarSource layerVisibility={radarVisibility} />
        <FullscreenControl style={{ right: 30, top: 10 }} />
        <RadarToggle setRadarVisibility={setRadarVisibility} radarVisibility={radarVisibility} />
        {flights && flights.filter((f) => f.altitude >= 1 && f.groundspeed >= 50 && f.positionAge <= 300).map((flight) => <FlightMarker key={flight.TALON_ACT_ID} viewport={viewport} flight={flight} passChildData={setSelectedFlight} />)}
        {metars && metars.map((metar, index) => <WxMarker key={index} wx={metar} viewport={viewport} passChildData={setSelectedWx}></WxMarker>)}

        {viewport.zoom > 6 &&
          cameras !== null &&
          cameras.map((cam, index) => {
            return <CameraMarker key={index} cam={cam} viewport={viewport} passChildData={setSelectedCam} />;
          })}

        {selectedCam && <CameraMarkerPopup cam={selectedCam} passChildData={setSelectedCam} />}

        {selectedFlight && <FlightPopup flight={selectedFlight} passChildData={setSelectedFlight} />}

        {selectedFeature && (
          <Popup
            latitude={selectedFeature.clickLocation[1]}
            longitude={selectedFeature.clickLocation[0]}
            onClose={() => {
              setSelectedFeature(null);
            }}
          >
            <div>AREA: {selectedFeature.featureName}</div>
          </Popup>
        )}

        {selectedWx && <WxMarkerPopup wx={selectedWx} passChildData={setSelectedWx}></WxMarkerPopup>}
      </ReactMapGL>
    </div>
  );
}
