export const airplaneTypes =
{
    'C-GPBP': 'C172',
    'C-FUWN': 'C172',
    'C-FUWL': 'C172',
    'C-GDFF': 'BE9L',
    'C-GDHS': 'BE9L',
    'C-FSXG': 'BE9L',
    'C-FEGL': 'DA-20',
    'C-FNDC': 'DA-20',
    'C-FMZZ': 'DA-20',
    'C-FNIN': 'DA-20',
    'C-FNGN': 'DA-20',
    'C-FNTJ': 'DA-20',
    'C-FFQT': 'DA-20',
    'C-GAHQ': 'DA-20',
    'C-FVVY': 'DA-20',
    'C-FXJD': 'DA-20',
    'C-FNJW': 'DA-20',
    'C-FNXA': 'DA-20',
    'C-FRIZ': 'DA-20',
    'C-FJYQ': 'DA-20',
    'C-FDYW': 'DA-20',
    'C-FJYS': 'DA-20',
    'C-FLXL': 'DA-20',
    'C-FEFZ': 'DA-20',
    'C-GPUX': 'DA-20',
    'C-FFQM': 'DA-20',
    'C-FEFG': 'DA-20',
    'C-FXVZ': 'DA-20',
    'C-FRJU': 'DA-20',
    'C-FVVN': 'DA-20',
    'C-GMFI': 'DA-20',
    'C-GMFK': 'DA-20',
    'C-FCUS': 'DA-20',
    'C-FUZG': 'DA-20',
    'C-FNHO': 'DA-20',
    'C-GMFF': 'DA-20',
    'C-FSXF': 'DA-20',
    'C-FJZC': 'DA-20',
    'C-FNTK': 'DA-20',
    'C-FVVJ': 'DA-20',
    'C-GMFE': 'DA-20',
    'C-GHTO': 'DA-20',
    'C-FJXD': 'DA-20',
    'C-FSXA': 'DA-20',
    'C-FVBM': 'DA-20',
    'C-FUYV': 'DA-20',
    'C-GJSO': 'DA-20',
    'C-GNPE': 'DA-20',
    'C-FZKH': 'PA44',
    'C-FTUU': 'PA44',
    'C-FSUQ': 'PA44',
    'C-FXDK': 'PA44',
    'C-GMVV': 'PA44',
    'C-FZKG': 'PA44',
    'C-GMFC': 'PA44',
    'C-FHFS': 'PA44',


}