// COMPONENTS
import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Navbars from "./components/NavBars/NavBars";
import { AuthProvider } from "./components/Contexts/AuthContext";
import { FlightsProvider } from "./components/Contexts/FlightContext";
import PrivateRoute from "./components/ProtectedRoutes/PrivateRoute";
import { analytics } from "./config/firebase";

//PAGES

import { Planned } from "./components/FID/Planned/Planned";
import { Departed } from "./components/FID/Departed/Departed";
import { DailyFlightStats } from "./components/Stats/DailyFlightStats";
import Dashboard from "./Pages/Dashboard";
import Map from "./Pages/Map";

import { Maintenance } from "./components/Maintenance/Maintenance";
import { Signup } from "./components/Auth/Signup";
import { Login } from "./components/Auth/Login";
import { Cador } from "./components/Cador/Cador";
import { AppAbout } from "./components/AppAbout/AppAbout";
import Kiosk from "./Pages/Kiosk";

import Userprofile from "./components/Admin/Userprofile";
import { ResetPassword } from "./components/Auth/ResetPassword";
import { UpdateProfile } from "./components/Admin/UpdateProfile";
import Admin from "./components/Admin/Admin";
import { BiAdmin } from "./components/Admin/BiAdmin";

const App = () => {
  return (
    <div className="App">
      <Container fluid className="containerMain">
        <BrowserRouter>
          <AuthProvider>
            <FlightsProvider>
              <Row noGutters>
                <Col>
                  <Navbars />
                  <Switch>
                    <PrivateRoute
                      exact
                      path="/"
                      component={Dashboard}
                      userRole="general"
                    />
                    <PrivateRoute
                      exact
                      path="/dashboard"
                      component={Dashboard}
                      userRole="general"
                    />
                    {/* <PrivateRoute
                                            path="/planned"
                                            component={() => <Planned location={'CYQM'}/>}
                                            userRole='flightOps'

                                        /> */}
                    <PrivateRoute
                      path="/plannedcyfc"
                      component={() => <Planned location={"CYFC"} />}
                      userRole="flightOps"
                    />
                    <PrivateRoute
                      path="/plannedcyqm"
                      component={() => <Planned location={"CYQM"} />}
                      userRole="flightOps"
                    />
                    <PrivateRoute
                      path="/plannedcyrt"
                      component={() => <Planned location={"CYRT"} />}
                      userRole="flightOps"
                      location="CYRT"
                    />
                    <PrivateRoute
                      path="/plannedcyth"
                      component={() => <Planned location={"CYTH"} />}
                      userRole="flightOps"
                      location="CYTH"
                    />
                    <PrivateRoute
                      path="/departedcyfc"
                      component={() => <Departed location={"CYFC"} />}
                      userRole="flightOps"
                      location="CYFC"
                    />

                    <PrivateRoute
                      path="/departedcyqm"
                      component={() => <Departed location={"CYQM"} />}
                      userRole="flightOps"
                      location="CYQM"
                    />

                    <PrivateRoute
                      path="/departedcyrt"
                      component={() => <Departed location={"CYRT"} />}
                      userRole="flightOps"
                      location="CYRT"
                    />

                    <PrivateRoute
                      path="/departedcyth"
                      component={() => <Departed location={"CYTH"} />}
                      userRole="flightOps"
                      location="CYTH"
                    />

                    <PrivateRoute
                      path="/map"
                      component={Map}
                      userRole="flightOps"
                    />
                    <PrivateRoute
                      path="/maint"
                      component={Maintenance}
                      userRole="maintenance"
                    />
                    <PrivateRoute
                      path="/cador"
                      component={Cador}
                      userRole="opsMgmt"
                    />
                    <PrivateRoute
                      path="/dailystats"
                      component={DailyFlightStats}
                      userRole="opsMgmt"
                    />
                    <PrivateRoute
                      path="/profile"
                      component={Userprofile}
                      userRole="general"
                    />
                    <PrivateRoute
                      path="/update-profile"
                      component={UpdateProfile}
                      userRole="general"
                    />

                    <PrivateRoute
                      path="/admin"
                      component={Admin}
                      userRole="admin"
                    />
                    <PrivateRoute
                      path="/bi-admin"
                      component={BiAdmin}
                      userRole="admin"
                    />
                    <PrivateRoute
                      path="/appabout"
                      component={AppAbout}
                      userRole="general"
                    />
                    <Route path="/sign-up" component={Signup} />
                    <Route path="/login" component={Login} />
                    <Route path="/reset-password" component={ResetPassword} />
                    <Route path="/kiosk/2333404272" component={Kiosk} />

                    <Redirect from="/" to="/dashboard" />
                  </Switch>
                </Col>
              </Row>
            </FlightsProvider>
          </AuthProvider>
        </BrowserRouter>
        {/* <div className="footer"></div> */}
      </Container>
    </div>
  );
};
export default App;
