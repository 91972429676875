import React, { useRef, useState } from 'react';
import { Alert, Image, Container, Form, Button, Card } from 'react-bootstrap';
import { useAuth } from '../Contexts/AuthContext';
import { Link } from 'react-router-dom';

export const ResetPassword = () => {
    const emailRef = useRef();
    const { resetPassword } = useAuth();
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setMessage('');
            setError('');
            setLoading(true);
            await resetPassword(emailRef.current.value);

            setMessage('Check your inbox for further instructions');
        } catch (err) {
            setError(`Failed to reset password. ${err.message}`);
        }
        setLoading(false);
    };

    return (
        <>
            <Container
                className="mt-5 align-items-center justify-content-center"
                style={{ maxWidth: '500px' }}
            >
                <Card className="bg-dark">
                    <Card.Body>
                        <Image
                            className="pl-2 pr-5"
                            fluid
                            src="images/mfcLogo_white.png"
                        />
                        <h2 className="mb-4 text-center">Reset Password</h2>
                        {error && <Alert variant="danger">{error}</Alert>}
                        {message && <Alert variant="success">{message}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    ref={emailRef}
                                    required
                                />
                            </Form.Group>

                            <Button
                                disabled={loading}
                                className="mt-2 pt-3 pb-3 w-100"
                                type="submit"
                            >
                                Reset password
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
            <div className="w-100 text-center mt-3">
                <Link to="/login">Login</Link>
            </div>
            <div className="w-100 text-center mt-2">
                Need an Account? Contact an Airboss admin.
            </div>
        </>
    );
};
