import React from 'react';

import TopNav from './TopNav';
import BottomNav from './BottomNav';
import { useAuth } from '../Contexts/AuthContext';
import {useFlights} from '../Contexts/FlightContext';

const NavBars = () => {
    const { currentUser } = useAuth();
    // const {socketConnected} = useFlights()
    return (
    <>
    
    {(currentUser && !currentUser.isAnonymous) && <TopNav />}
    {(currentUser && !currentUser.isAnonymous) && <BottomNav />}
    </>)
};

export default NavBars;
