import React from 'react'
import { Container } from 'react-bootstrap'

export const AppAbout = () => {
    let appVersion = process.env.REACT_APP_VERSION


    return (
        <Container>
            <div className='text-center mt-5 py-5'>
                <div className="h1">AirBoss for MFC</div>
                <div className="h5 mt-5">By Elie Tech Services, © 2021</div>
                <div className="h6">Build Version: {appVersion}</div>
            </div>
        </Container>
    )
}
