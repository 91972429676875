import React, { useContext, useState, useEffect } from 'react';
import firebase, { auth } from '../../config/firebase';
import axios from 'axios';



const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    async function signup(newUser) {
        try {
            const user = newUser
            const token = await auth.currentUser.getIdToken();
            const url = process.env.REACT_APP_AIRBOSS_URL;
            return axios.post(`${url}/admin/user`, user, {
                headers: { authorization: `Bearer ${token}` },
            })
        } catch (error) {
            console.log(error)
            setError(error)
        }
    }

    async function updateUser(uid, userData) {
        const user = userData
        const token = await auth.currentUser.getIdToken();
        const url = process.env.REACT_APP_AIRBOSS_URL;
        let data = user
        return axios
            .patch(`${url}/admin/user/${uid}`, data, {
                headers: { authorization: `Bearer ${token}` },
            })
            .then((res) => res.data);
    }

    async function deleteUser(uid) {
        const token = await auth.currentUser.getIdToken();
        const url = process.env.REACT_APP_AIRBOSS_URL;

        return axios.delete(`${url}/admin/user/${uid}`,
            {
                headers: { authorization: `Bearer ${token}` },
            })
            .then((res) => res.data);
    }



    function anonSignIn() {
        return auth.signInAnonymously();

    }
    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password);
    }

    function logout() {
        return auth.signOut();
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email);
    }

    function updateEmail(email) {
        return auth.currentUser.updateEmail(email);
    }

    function updatePassword(password) {
        return auth.currentUser.updatePassword(password);
    }

    function updateDisplayName(displayName, photoURL) {
        return auth.currentUser.updateProfile({
            displayName,
            photoURL,
        });
    }
    function getToken() {

        return auth.currentUser.getIdToken();
    }
    async function getUsers() {
        const token = await auth.currentUser.getIdToken();
        const url = process.env.REACT_APP_AIRBOSS_URL;

        return axios
            .get(`${url}/admin/users`, {
                headers: { authorization: `Bearer ${token}` },
            })
            .then((res) => res.data);
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                setLoading(true)
                try {
                    //When an Auth State Changes (user logs in) complete the following:
                    const idTokenResult = await user.getIdTokenResult();    //Grab the ID Token from Firebase (contains custom claims)
                    let claims = idTokenResult.claims
                    setCurrentUser({ ...user, claims });  // Combine the idToken, and the userDetails into the existing user object and set state.

                    setLoading(false);

                } catch (e) {
                    setError(e);
                    setLoading(false);
                }
            } else {
                setCurrentUser(undefined);
                setLoading(false);
            }

        });
        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        signup,
        updateUser,
        deleteUser,
        anonSignIn,
        login,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
        updateDisplayName,
        getUsers,
        getToken,
    };
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
