import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Card, CardDeck, Form, Alert} from 'react-bootstrap'

import {getAircraftInfo} from '../../api/api'
import { getCadors } from '../../api/cadorAPI'
import { NewCadorModal } from './NewCadorModal'
import { EditCadorModal } from './EditCadorModal'
import {CadorTable} from './CadorTable'

export const Cador = () => {

    const [aircraftInfo, setAircraftInfo] = useState();
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [cador, setCador] = useState();
    const [cadorLookup, setCadorLookup] = useState();
    const [newCadorModalOpts, setNewCadorModalOpts] = useState(

        {
            show: false,
            id: null,
            activityIDFilter: null
        }

    )
    const [editCadorModalOpts, setEditCadorModalOpts] = useState(

        {
            show: false,
            id: null,
            activityIDFilter: null
        }

    )
    //Load Aircraft List from TALON
    useEffect(()=>{
        getAircraftInfo().then(res => {
            setAircraftInfo(res)
        })
    }, [])


    const cadorDetailsToParent =(cadorId) =>{
        setCadorLookup(cadorId)

    }

    const handleCadorLookup = (e) => {
        // e.preventDefault();
        
        if (!cadorLookup) {
            setError('No CADOR Number Specified')
            return
        }

        setError(null);
        setLoading(true);
        
        getCadors(cadorLookup).then(res => {
            
            setLoading(false)
            if (!res.data) {
                throw new Error(res)
            }

            let data = res.data
            let cadorData = {
                cadorNumber: data.cadorNumber,
                occurrenceCategory: data.occurrenceCategory,
                TALON_ACT_ID: data.TALON_ACT_ID,
                occurrenceType: data.occurrenceType,
                occurrenceDate: data.occurrenceDate,
                occurrenceTime: data.occurrenceTime,
                dayOrNight: data.dayOrNight,
                aerodromeID: data.aerodromeID,
                occurrenceLocation:data.occurrenceLocation, 
                reportedBy: data.reportedBy,
                tsbClassOfInvestigation: data.tsbClassOfInvestigation,
                fatalities:data.fatalities,
                injuries: data.injuries,
                occurrenceEventInfo: data.occurrenceEventInfo,
                aircraftRegistration:data.aircraftRegistration,
                aircraftModel: data.aircraftModel,
                phaseOfFlight: data.phaseOfFlight,
                flightRules:data.flightRules,
                damage: data.damage,
                aircraftEventInfo: data.aircraftEventInfo,
                dateEntered: data.dateEntered,
                narrative: data.narrative,
                createdAt: data.createdAt,
                updatedAt: data.updatedAt,
                navErrors: data.navErrors,
                createdBy: data.createdBy,
                lastModifiedBy: data.lastModifiedBy,
                smsId: data.smsId
            }
            setCador(cadorData);
            let modalOptions = {
                show: true,
                id: null,
                activityIDFilter: null
                
            }
            setEditCadorModalOpts(modalOptions)

        }).catch(err => {
            setError(err.message)
        })


    }
  
    const handleNewCadorClick = (e) => {
        e.preventDefault();
        setNewCadorModalOpts(
            {
                show: true,
                id: null,
                actifityIDFilter: null
            }
        )

    }

    const closeNewCadorModal = () => {
        setNewCadorModalOpts(
            {
                show: false,
                id: null,
                actifityIDFilter: null
            }
        )

    }

    const closeEditCadorModal = () => {
        setEditCadorModalOpts(
            {
                show: false,
                id: null,
                actifityIDFilter: null
            }
        )
        setCadorLookup('')

    }

    return (

        <Container>
            <Row className="mt-4">
                <Col sm={12}>
                    <div className="h1 text-center">CADORs</div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col sm={12}>
                <CardDeck>
                    <Card bg="dark">
                        <Card.Header>
                            <Card.Title>Create CADOR</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            Create a new CADOR entry.
                        </Card.Body>
                        <Card.Footer>
                            <Button size='sm' className='mt-3 mb-3' variant='secondary' onClick={handleNewCadorClick}>CREATE CADOR</Button>
                            
                        </Card.Footer>
                    </Card>
                    <Card bg="dark">
                        <Card.Header>
                            <Card.Title>
                            Look-Up CADOR
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            Lookup an existing CADOR entry.
                            <Form onSubmit={(e)=>{handleCadorLookup(e)}}>
                                <Form.Group className="mt-2 " controlId='cadorNumberLookup'>
                                    <Form.Control type='text' value={cadorLookup} placeholder='Enter CADOR number' onChange={(e) => {
                                    // e.preventDefault();
                                    setCadorLookup(e.target.value);
                                    }
                                    
                                    }></Form.Control>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                        <Card.Footer>
                            <Button disabled={loading} type='submit' size='sm' className='mt-3 mb-3' variant='secondary' onClick={(e)=>{handleCadorLookup(e)}}>Look-Up Cador</Button>
                            {error &&  <Alert variant="danger">{error}</Alert>}
                        </Card.Footer>
                    </Card>

                </CardDeck>
                </Col>
                
                
            </Row>
            <Row className='mt-3'>
            <Col sm={12}>
                <CadorTable cadorDetailsToParent={cadorDetailsToParent}/>
            </Col>
            </Row>
                <NewCadorModal newCadorModalOpts={newCadorModalOpts} closeNewCadorModal={closeNewCadorModal}  aircraftInfo={aircraftInfo}/>
                <EditCadorModal editCadorModalOpts={editCadorModalOpts} closeEditCadorModal={closeEditCadorModal} aircraftInfo={aircraftInfo} cadorData={cador}/>
        </Container>

    )
}
