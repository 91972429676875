import React, { useState } from 'react';
import { Container, Image, Card, Button, Alert } from 'react-bootstrap';
import { useAuth } from '../Contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';

export default function Userprofile() {
    const [error, setError] = useState('');
    const { currentUser, logout} = useAuth();
    const history = useHistory();

    


    async function handleLogout() {
        setError('');

        try {
            await logout();
            history.push('/login');
        } catch (err) {
            setError(`Failed to logout.  ${err.message}`);
        }
    }

    
    return (
        <>
            <Container
                className="mt-5 align-items-center justify-content-center"
                style={{ maxWidth: '500px' }}
            >
                <Card className="bg-dark mt-5">
                    <Card.Body>
                        <h2 className="mb-4 text-center">User Profile</h2>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <div className="mt-2 mb-2 mr-2 ml-2">
                            <Image
                                src={`https://ui-avatars.com/api/?name=${currentUser.displayName}&background=18447d&color=fff`}
                                roundedCircle
                            />
                        </div>
                        <div className="mt-3">
                            <strong>Display Name: </strong>
                            {currentUser.displayName ? currentUser.displayName : ''}
                        </div>

                        <div className="mt-3">
                            <strong>Email: </strong>
                            {currentUser.email}
                        </div>
                        {currentUser.phoneNumber &&
                            <div className="mt-3">
                                <strong>Mobile Num: </strong>
                                {currentUser.phoneNumber}
                            </div>
                        
                        }
                        <div className="mt-3">
                            <strong>Admin: </strong>
                            {currentUser.claims.admin ? ' Yes' : ' No'}
                        </div>

                        <Link
                            to="/update-profile"
                            className="btn btn-primary w-100 mt-3"
                        >
                            Update Profile
                        </Link>
                    </Card.Body>
                </Card>
            </Container>
            <div className="w-100 text-center mt-2">
                <Button variant="link" onClick={handleLogout}>
                    Log Out{' '}
                </Button>
            </div>
        </>
    );
}
