import React, { useEffect, useState } from 'react';
import { useFlights } from '../Contexts/FlightContext';
import { Card, Table } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';





const totalHoursByType = (base, dailyHours, aircraftInfo) => {
    if (dailyHours.length === 0 || aircraftInfo.length === 0) return
    if (!base) return 'Base is not defined'
    const lookupType = (resId) => {
        if (resId === 'sim') return 'sim';

        let aircraft = aircraftInfo.filter(ac => ac.AIRCRAFT_ID === resId)
        if (aircraft.length > 0) return aircraft[0].RESOURCE_TYPE
        return

    }
    if (!dailyHours[base]) return;
    if (!dailyHours[base].resId) return;
    let keys = Object.keys(dailyHours[base].resId)
    let hoursByType = {

    }
    let hoursArray = [];
    keys.forEach(key => {

        let type = lookupType(key);
        let hours = dailyHours[base];

        if (!hoursByType[type]) {
            hoursByType[type] = hours.resId[key]

        } else {
            hoursByType[type] = hours.resId[key] + hoursByType[type]
        }
    })

    let propertyNames = Object.keys(hoursByType);

    propertyNames.map(propName => {
        let item = {
            type: propName,
            hours: hoursByType[propName]
        }
        hoursArray.push(item);
    })


    return hoursArray

}

export const DailyFlightStats = ({ base, totalHours }) => {
    const { dailyHours, aircraftInfo, acStatus } = useFlights();
    const [hoursByType, setHoursByType] = useState();
    const [acList, setAcList] = useState();

    useEffect(() => {
        if (acStatus) {
            if (dailyHours && aircraftInfo) {
                let hours = totalHoursByType(base, dailyHours, aircraftInfo)
                setHoursByType(hours)

            }
            console.log(`ACStatus for ${base}: ${JSON.stringify(acStatus[base])}`)
            if (acStatus[base]) {
                let list = (Object.keys(acStatus[base].DOWN))
                list.push('sim')
                setAcList(list);
            }
        };


    }, [dailyHours, aircraftInfo, acStatus])



    return (

        <Card
            bg='dark'
            className='shadow'
        >
            {acStatus &&
                <div>
                    {!hoursByType &&
                        <>
                            <Card.Header>
                                <Card.Title>
                                    <div>
                                        {base}
                                    </div>
                                    <div>
                                        Total Hours: N/A
                                    </div>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {/* <Spinner animation="border" role="status"></Spinner> */}
                                <div>N/A</div>
                            </Card.Body>
                        </>

                    }
                    {hoursByType &&

                        <>
                            <Card.Header>
                                <Card.Title>
                                    <div>
                                        {base}
                                    </div>
                                    <div>
                                        Total Hours: {totalHours.toFixed(1)}<span className='h6 text-muted'> hrs</span>

                                    </div>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Table
                                    borderless
                                    size='sm'
                                    resonsive="xl"
                                    className="text-center"
                                    id="flights"
                                >
                                    <thead className='h5 text-light bg-dark>'>
                                        <tr>
                                            <th>Hours</th>
                                            <th>Type</th>
                                            <th className='text-success'>Up</th>
                                            <th className='text-danger'>Down</th>
                                        </tr>
                                    </thead>

                                    {(hoursByType && acList) &&
                                        <tbody className="h6 text-light bg-dark">

                                            {acList.map((acType, index) => {
                                                let hrs = hoursByType.filter(e => e.type === acType);
                                                let up = acStatus[base].UP[acType];
                                                let down = acStatus[base].DOWN[acType];

                                                return (
                                                    <tr key={index}>
                                                        <td className=''>{hrs.length > 0 ? hrs[0].hours.toFixed(1) : 0}</td>
                                                        <td className=''>{acType.toUpperCase()}</td>
                                                        <td>{acType !== 'sim' ? up : '--'}</td>
                                                        <td>{acType !== 'sim' ? down : '--'}</td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    }
                                </Table>
                            </Card.Body>
                        </>
                    }
                </div>
            }

        </Card>
    )
}


