import React, { useEffect, useState } from 'react'
import Map from '../Pages/Map'
import { useAuth } from '../components/Contexts/AuthContext';
import { Col, Container, Row } from 'react-bootstrap';
import WxCam from '../components/Weather/WxCam';
import { Departed } from '../components/FID/Departed/Departed';
import { Planned } from '../components/FID/Planned/Planned';
import {WeatherStatus} from '../components/Weather/WeatherStatus'
import { useFlights } from '../components/Contexts/FlightContext'



export default function Kiosk() {
    const { anonSignIn } = useAuth();
    const [authStatus, setAuthStatus] = useState();
    const { dailyHours, aircraftInfo, metar } = useFlights()

    useEffect(() => {
        (async () => {
            let response = await anonSignIn();
            setAuthStatus(response)
        })()

    }, [])

    return (
        <>
            {authStatus &&

                <Container fluid>
                    <Row>
                        <Col md={6}>
                            <Row className='dash-row'>
                                <Col sm={4} className='dash-col'>
                                    <WeatherStatus base={'CYFC'} kiosk={true} weather={metar} />
                                </Col>
                                <Col sm={4} className="dash-col">
                                    <WeatherStatus base={'CYQM'} kiosk={true} weather={metar} />
                                </Col>
                                <Col sm={4} className="dash-col">
                                    <WeatherStatus base={'CYYR'} kiosk={true} weather={metar} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={5} className=''>
                                    <Departed location={'CYQM'} kiosk={true} />
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5} className=''>
                                    <Departed location={'CYFC'} kiosk={true} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={5} className=''>

                                    <Planned location={'CYQM'} kiosk={true} />
                                </Col>
                                <Col md={1}></Col>
                                <Col md={5} className=''>
                                    <Planned location={'CYFC'} kiosk={true} />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row className="">
                                <Map mapWidth={"50vw"} mapHeight={"65vh"}  />
                            </Row>
                            <Row>
                                <Col sm={12} md={6} lg={3} className="dash-col">

                                    <WxCam direction="N" />
                                </Col>
                                <Col sm={12} md={6} lg={3} className="dash-col">

                                    <WxCam direction="E" />
                                </Col>
                                <Col sm={12} md={6} lg={3} className="dash-col">

                                    <WxCam direction="SW" />
                                </Col>
                                <Col sm={12} md={6} lg={3} className="dash-col">

                                    <WxCam direction="W" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>

            }
        </>
    )
}
