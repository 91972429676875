import moment from "moment-timezone";
const { getCxFlight } = require("../../../../api/api");
// const tz = "America/Moncton"; //Timezone Info

export const filterFlights = (
  flights,
  startOffsetMinutes,
  endOffsetMinutes
) => {
  //Filter flights to only include RAMP OUT flights
  let oldestTime = moment().subtract(startOffsetMinutes, "minutes");
  let newestTime = moment().add(endOffsetMinutes, "minutes");

  return flights.filter(
    (f) =>
      ["Scheduled", "Cancelled", "Ops Check In", "Authorized"].indexOf(
        f.STATUS
      ) !== -1 &&
      f.ACTIVITY_TYPE === "Flight" &&
      f.ACTIVITY_START.isBefore(newestTime) &&
      f.ACTIVITY_START.isAfter(oldestTime)
  );
};

export const sortFlights = (flights) => {
  return flights.sort((a, b) => a.ACTIVITY_START - b.ACTIVITY_START);
};

export const processFlights = (flights) => {
  flights.forEach((flight) => {


    let tz;

    if (flight.LOCATION === "MFC Moncton" || flight.LOCATION === "MFC Fredericton") {
      tz = "America/Moncton";
    }

    if (flight.LOCATION === "MFC Rankin Inlet" || flight.LOCATION === "MFC Thompson") {
      tz = "America/Winnipeg";
    }

    //Change ACTIVITY_START and ACTIVITY_DUEBACK to MOMENTS and change LOCATION to AIRPORT ID
    flight.ACTIVITY_START = moment.tz(
      flight.ACTIVITY_START,
      "DD MMM YYYY hh:mm",
      tz
    );
    flight.ACTIVITY_DUEBACK = moment.tz(
      flight.ACTIVITY_DUEBACK,
      "DD MMM YYYY hh:mm",
      tz
    );

    //Add TTG value for each flight

    let a = flight.ACTIVITY_START;
    let b = new moment().tz(tz);
    flight.TTG = a.diff(b, "minutes");

    if (flight.LOCATION === "MFC Moncton") {
      flight.LOCATION = "CYQM";
    }
    if (flight.LOCATION === "MFC Fredericton") {
      flight.LOCATION = "CYFC";
    }
    if (flight.LOCATION === "MFC Rankin Inlet") {
      flight.LOCATION = "CYRT";
    }
    if (flight.LOCATION === "MFC Thompson") {
      flight.LOCATION = "CYTH";
    }
  });

  return flights;
};

export const addCxReason = async (flights) => {
  try {
    let promises = [];

    let cxFlights = flights.filter((f) => f.STATUS === "Cancelled");

    cxFlights.forEach((flight) => {
      let promise = getCxFlight(flight.ACTIVITY_ID);
      promises.push(promise);
    });

    return Promise.all(promises).then((responses) => {
      let cxReasons = responses.filter((e) => e); // Remove undefined AKA missing CX Flight Data

      try {
        if (!cxReasons || cxReasons.length === 0)
          throw new Error("No CX Flight Reasons found");

        flights.forEach((flight) => {
          if (flight.STATUS === "Cancelled") {
            const cxEntry = cxReasons.filter(
              (cxReason) => cxReason.SCH_ACT_ID === flight.ACTIVITY_ID
            );
            if (cxEntry.length > 0) {
              flight.cxReason = cxEntry[0].ACT_CMNT;
            }
          }
        });
        return flights;
      } catch (error) {
        console.log('Error with "addCxReason" function:', error);
      }
    });
  } catch (error) {
    console.log(error);
  }
};
