import React,{ } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'





const LoadingBar = () => {
    return (
        <ProgressBar>
            <ProgressBar animated now={100} />
            
        </ProgressBar>
    )
}

export default LoadingBar