import React, { useEffect, useState } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import { Alert, Container, Row, Col, Button, Modal, Card, Form } from 'react-bootstrap';
import axios from 'axios';
import UsersTable from './UsersTable';
import { UserDetails } from './UserDetails'
import shortid from 'shortid'
import LoadingBar from '../LoadingBar/LoadingBar';


const Users = () => {

    const { signup, updateUser, getToken } = useAuth()
    const [users, setUsers] = useState();
    const [keys, setKeys] = useState();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false)




    const [newUser, setNewUser] = useState(
        {
            details:
            {
                email: null,
                emailVerfified: true,
                password: shortid.generate(),
                displayName: null,
            },
            customClaims:
            {
                admin: false,
                general: true,
                flightOps: false,
                opsMgmt: false,
                maintenance: false,
                instructor: false,
                student: false,
            }
        })

    const [modalOptions, setModalOptions] = useState(
        {
            show: false,
            id: null,
            activityIDFilter: null
        }
    );
    const [addUserModalOptions, setAddUserModalOptions] = useState(
        {
            show: false,
            id: null,
            activityIDFilter: null
        }
    );

    async function getUsers() {
        const promises = [];
        const token = await getToken();
        const url = process.env.REACT_APP_AIRBOSS_URL;
        const headers = {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        }

        let { data } = await axios.get(`${url}/admin/users`, {
            headers: headers,
        })

        for (let x = 0; x < data.length; x++) {
            let user = data[x];

            let promise = await axios.get(`${url}/admin/user/${user.uid}`,
                {
                    headers: { authorization: `Bearer ${token}` },
                })

            let promiseData = { ...promise.data[0] }
            let userAccess = promiseData.userAccess
            let newUser = { ...user, ...userAccess }
            promises.push(newUser)
        }
        return await Promise.all(promises)
    }



    useEffect(() => {
        (async () => {
            setLoading(true)
            getUsers().then((data) => {
                setUsers(data);
                let keys = Object.keys(data[0])
                delete keys['customClaims'];
                setKeys(keys);
                setLoading(false)
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshUsers = async () => {
        getUsers().then((data) => {
            setUsers(data);
            let keys = Object.keys(data[0])
            delete keys['customClaims'];
            setKeys(keys);
        });
    }

    const userDetailsToParent = (id) => {

        let options = {
            show: true,
            id: id,
            activityIDFilter: id
        }
        setModalOptions(options)
    }

    const closeUserDetails = () => {
        let options = {
            show: false,
            id: null,
            activityIDFilter: null
        }
        setModalOptions(options)
        refreshUsers();


    }

    const handleClickNewUser = (e) => {
        let options = {
            show: true,
            id: null,
            activityIDFilter: null
        }
        setAddUserModalOptions(options)
    }

    const closeAddUser = () => {
        let options = {
            show: false,
            id: null,
            activityIDFilter: null
        };
        setError('');
        setSuccess(false)

        setAddUserModalOptions(options)
        refreshUsers();
    }

    const updateUserDetails = async (e) => {
        e.preventDefault()
        let uid = e.uid
        let user = e.user
        try {
            setError('');
            setLoading(true);
            await updateUser(uid, user)
            setSuccess(true);

        } catch (err) {
            setError(`Account Creation Failed. ${err.message}`);
        }
        setLoading(false)
        refreshUsers();
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault()

        try {
            setError('');
            setLoading(true);
            let response = await signup(newUser)
            setSuccess(true);

        } catch (err) {
            setError(`${err.response.data}`);
            setLoading(false)

        }
        setLoading(false)
        refreshUsers();

    }

    return (
        <>
            <Container >
                <React.Fragment>
                    {users && 
                        <div className='text-center mt-5'>
                            <h1 >Users</h1>
                            <h5>Select a user below to modify</h5>
                        </div>
                    }
                    {!users && 
                        <>
                        <div className='text-center mt-5 mb-5 h3'>Fetching Users...</div>
                            <LoadingBar/>

                        </>
                    }

                    {users && keys &&
                        <UsersTable keys={keys} users={users} userDetailsToParent={userDetailsToParent} />
                    }
                    {modalOptions.show &&
                        <UserDetails user={users.filter(u => u.uid === modalOptions.id)[0]} modalOptions={modalOptions} updateUserDetails={updateUserDetails} closeUserDetails={closeUserDetails} />
                    }
                    <Button disabled={loading} className='text-center mt-2' size='sm' variant='secondary' id='addUserButton' onClick={handleClickNewUser}>Add New User</Button>
                </React.Fragment>
                
                {/* Add New User Modal */}
                <Modal
                    size=''
                    dialogClassName="modalStyle"
                    animation={true}
                    show={addUserModalOptions.show}
                    onHide={() => closeAddUser()}
                    align-items-center="true"
                    justify-content-center="true"
                    className='mt-5'
                >
                    <div className="text-light bg-dark text-center">

                        <Card className="bg-dark">
                            <Card.Header>Add New User</Card.Header>
                            <Card.Body>
                                {error && <Alert variant="danger">{error}</Alert>}
                                <Form>
                                    <Row>
                                        <Col sm={12} md={4}>
                                            <Form.Group>
                                                <Form.Label>Display Name</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Display Name" onChange={(d) => {
                                                    const u = { ...newUser };
                                                    u.details.displayName = d.target.value
                                                    setNewUser(u)
                                                }
                                                }>

                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Form.Group>
                                                <Form.Label>Email Address</Form.Label>
                                                <Form.Control type="email" placeholder="Enter email" onChange={(d) => {
                                                    const u = { ...newUser };
                                                    u.details.email = d.target.value
                                                    setNewUser(u)
                                                }}>

                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Form.Group>
                                                <Form.Label>Mobile Number</Form.Label>
                                                <Form.Control type="tel"  required placeholder="Req Format: +12223334444" onChange={(d) => {
                                                    const u = { ...newUser };
                                                    u.details.phoneNumber = d.target.value
                                                    setNewUser(u)
                                                }}>
                                                {/* <div className="text-muted">Phome Number must be of format +15551231234 where +1 is your Country Code.</div> */}
                                                </Form.Control>
                                                <Form.Label>
                                                <div className="text-muted">Mobile number required for multifactor authentication.</div>
                                            </Form.Label>
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col className="mt-4 mb-1" sm={12}>
                                            <div className='h5'>Give user ADMIN privileges</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Form.Group controlId="formBasicCheckbox">
                                                <Form.Check type="checkbox" on label="Admin" onChange={(d) => {
                                                    const u = { ...newUser };
                                                    u.customClaims.admin = !newUser.customClaims.admin
                                                    setNewUser(u)
                                                }} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-1" sm={12}>
                                            <div className='text-muted'>Admin privileges are required to be able to add/remove other users.</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mt-4 mb-1" sm={12}>
                                            <div className='h5'>Select User Access Levels</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={2}>
                                            <Form.Group controlId="generalCheckBox">
                                                <Form.Check type="checkbox" label="General" defaultChecked disabled='true' readOnly='true' />

                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={2}>
                                            <Form.Group controlId="fltOpsCheckBox">
                                                <Form.Check type="checkbox" label="Flight Ops" onChange={(d) => {
                                                    const u = { ...newUser };
                                                    u.customClaims.flightOps = !newUser.customClaims.flightOps
                                                    setNewUser(u)
                                                }} />
                                            </Form.Group>
                                        </Col>

                                        <Col sm={12} md={2}>
                                            <Form.Group controlId="instructorCheckBox">
                                                <Form.Check type="checkbox" label="Instructor" onChange={(d) => {
                                                    const u = { ...newUser };
                                                    u.customClaims.instructor = !newUser.customClaims.instructor
                                                    setNewUser(u)
                                                }} />
                                            </Form.Group>
                                        </Col>

                                        <Col sm={12} md={2}>
                                            <Form.Group controlId="studentCheckBox">
                                                <Form.Check type="checkbox" label="Student" onChange={(d) => {
                                                    const u = { ...newUser };
                                                    u.customClaims.student = !newUser.customClaims.student
                                                    setNewUser(u)
                                                }} />
                                            </Form.Group>
                                        </Col>

                                        <Col sm={12} md={2}>
                                            <Form.Group controlId="maintCheckBox">
                                                <Form.Check type="checkbox" label="Maintenance" onChange={(d) => {
                                                    const u = { ...newUser };
                                                    u.customClaims.maintenance = !newUser.customClaims.maintenance
                                                    setNewUser(u)
                                                }} />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={2}>
                                            <Form.Group controlId="fltOpsMgmtCheckBox">
                                                <Form.Check type="checkbox" label="Flt Ops Management" onChange={(d) => {
                                                    const u = { ...newUser };
                                                    u.customClaims.opsMgmt = !newUser.customClaims.opsMgmt
                                                    setNewUser(u)
                                                }} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mb-1" sm={12}>
                                            <div className='text-muted'>Access levels control the areas that the user is able to access.  Select all that are applicable.  The "General" user level is applicable to all users.</div>
                                        </Col>
                                    </Row>



                                </Form>
                            </Card.Body>
                            <Card.Footer>
                                {success &&

                                    <>
                                        <Alert variant="success">User Created</Alert>
                                        <Button variant='secondary' size='sm' className="mx-2 my-1" onClick={closeAddUser}>Close</Button>
                                    </>
                                }
                                {!success &&
                                    <>
                                        <Button disabled={loading} variant='secondary' size='sm' className="mx-2 my-1" type="submit" onClick={handleFormSubmit}>Add User</Button>
                                        <Button disabled={loading} variant='secondary' size='sm' className="mx-2 my-1" onClick={closeAddUser}>Cancel</Button>

                                    </>
                                }


                            </Card.Footer>
                        </Card>

                    </div>
                </Modal>
            </Container>


        </>

    );
};

export default Users;


