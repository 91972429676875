import React, { useState } from 'react';

import { Image, Card, Button, Modal } from 'react-bootstrap';

export default function WxCam({ direction, flight, modalOptions, closeFlightDetails }) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let title = ''
    if (direction === 'N') {
        title = 'North'
    } else if (direction === 'W') {
        title = 'West'
    } else if (direction === 'E') {
        title = 'East'
    } else if (direction === 'SW') {
        title = 'South West'
    }
    return (
        <>
            <Card
                bg="dark"
                className='shadow'
            >
                <Card.Header>
                    <Card.Title>
                        {title}
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Image
                        fluid
                        className="pl-2 pr-2 pd-2 pb-2"
                        src={`https://www.metcam.navcanada.ca/dawc_images/wxcam/CYFC/CYFC_${direction}-full-e.jpeg`}
                        rounded
                    />
                </Card.Body>
                <Card.Footer>
                    <Button size="sm" onClick={handleShow} variant="outline-secondary">Ref Image</Button>
                </Card.Footer>
            </Card>
            <Modal
                bg="dark"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={handleClose}
                
                
            >
            
            <div className="text-light bg-dark text-center">
                
                <Modal.Title><div className="text-center">Reference Image: {title}</div></Modal.Title>
                <Modal.Body>
                    <Image src={`https://www.metcam.navcanada.ca/dawc_images/wxcam/CYFC/CYFC_${direction}-full-ref-e.jpeg`} fluid></Image>
                </Modal.Body>
                
            </div>
            
            
                
            </Modal>

        </>
    );
}
