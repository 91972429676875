import {
  faPlane,
  faWrench,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FleetStatus } from "./FleetStatus";

export interface ITypeStatus {
  acType: string;
  up: number;
  down: number;
}

export const UpDownTable = ({
  currentAcStatus,
  base,
}: {
  currentAcStatus: any;
  base: string;
}) => {
  const tableHeader = ["Type", "UP", "DN"];
  const index = currentAcStatus.length - 1;

  if (!currentAcStatus[index][base]) {
    return (
      <Card bg="dark">
        <Card.Header>
          <Card.Title>{base} Availability</Card.Title>
        </Card.Header>
        <Card.Body>
          <h6>No Data Found</h6>
        </Card.Body>
      </Card>
    );
  }

  const upAircraft = currentAcStatus[index][base.toUpperCase()].UP;
  const dnAircraft = currentAcStatus[index][base.toUpperCase()].DOWN;
  let acTypes = Object.keys(upAircraft);

  let status: ITypeStatus[] = [];
  acTypes.forEach((type) => {
    let obj = {
      acType: type,
      up: upAircraft[type],
      down: dnAircraft[type],
    };
    status.push(obj);
  });

  return (
    <div>
      <Card bg="dark">
        <Card.Header>
          <Card.Title>
            <div className="">{base}</div>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Card className="acStatusCard  shadow">
            <Card.Header className="pb-0">
              <Row className="">
                <Col className="" xs={6}>
                  <div className="h5">
                    <FontAwesomeIcon icon={faPlane} />
                  </div>
                </Col>
                <Col className="" xs={3}>
                  <div className="h5">
                    <FontAwesomeIcon icon={faArrowUp} />
                  </div>
                </Col>
                <Col className="" xs={3}>
                  <div className="h5">
                    <FontAwesomeIcon icon={faArrowDown} />
                  </div>
                </Col>
              </Row>
            </Card.Header>

            {status && (
              <Card.Body>
                {status.map((item, index) => {
                  return (
                    
                      <FleetStatus
                        key={index}
                        typeStatus={item as ITypeStatus}
                      />
                    
                  );
                })}
              </Card.Body>
            )}
          </Card>
        </Card.Body>
      </Card>
    </div>
  );
};
