import React, { useContext, useState, useEffect, useRef } from "react";
import { auth } from "../../config/firebase";
import { useAuth } from "../Contexts/AuthContext";

// import io from 'socket.io-client';
import moment from "moment-timezone";
import { useInterval } from "../../hooks/useInterval";
import { getAircraftInfo, getMetar } from "../../api/api";

import axios from "axios";

// let socketEndpoint = process.env.REACT_APP_WEBSOCKET_ENDPOINT;
const FlightContext = React.createContext();

export function useFlights() {
  return useContext(FlightContext);
}

export function FlightsProvider({ children }) {
  const [airborneFlights, setAirborneFlights] = useState("");
  const [talonFlights, setTalonFlights] = useState();
  const [dailyHours, setDailyHours] = useState();
  const [acStatus, setAcStatus] = useState();
  const [aircraftInfo, setAircraftInfo] = useState();
  const [metar, setMetar] = useState();

  const { getToken } = useAuth();

  // const [error, setError] = useState();
  // const [socketConnected, setSocketConnected] = useState(false);
  // const socket = useRef();

  const getAcStatus = async (start, end) => {
    if (!start && !end) {
      start = new Date();
      end = new Date();
    }

    let url = process.env.REACT_APP_AIRBOSS_URL;

    try {
      const token = await getToken();
      const { data } = await axios.get(
        `${url}/talon/acstatus/?startDate=${start.toISOString()}&endDate=${end.toISOString()}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      if (data.length === 0) throw new Error("No results found.");

      return data;
    } catch (error) {
      console.log("UpDown Stats Error: ", error);
    }
  };

  async function getTalonFlights(timeStamp) {
    let end, start, startDate, startTime, endDate, endTime;

    const tz = "America/Moncton";

    if (!timeStamp) {
      start = moment(new Date()).tz(tz).subtract(12, "hours");
      end = moment(new Date()).tz(tz).add(12, "hours");
      startDate = start.format("DD MMM YYYY").toString();
      startTime = start.format("HH:mm").toString();
      endDate = end.format("DD MMM YYYY").toString();
      endTime = end.format("HH:mm").toString();
    } else {
      startDate = moment(timeStamp)
        .subtract(4, "hours")
        .tz(tz)
        .format("DD MMM YYYY")
        .toString();
      startTime = moment(timeStamp)
        .subtract(4, "hours")
        .tz(tz)
        .format("HH:mm")
        .toString();
      endDate = moment(timeStamp)
        .add(1, "hours")
        .tz(tz)
        .format("DD MMM YYYY")
        .toString();
      endTime = moment(timeStamp)
        .add(1, "hours")
        .tz(tz)
        .format("HH:mm")
        .toString();
    }

    try {
      const { data } = await axios.get(
        `https://provincial.elietech.ca/web/api/talon/operations/?startDate=${startDate}&startTime=${startTime}&endDate=${endDate}&endTime=${endTime}`,
        {
          auth: {
            username: process.env.REACT_APP_mfcTalonUser,
            password: process.env.REACT_APP_mfcTalonPass,
          },
        }
      );
      return data;
    } catch (err) {
      console.log("getTalonFlights error: ", err);
    }
  }

  async function getAirborneFlights() {
    const baseURL =
      process.env.REACT_APP_FLIGHTS_API_BASEURL || "http://localhost:5000/api";
    try {
      const token = await getToken();
      const { data } = await axios.get(baseURL + "/flights", {
        headers: { authorization: `Bearer ${token}` },
      });
      return data;
    } catch (err) {
      console.log("getAirborneFlights Error: ", err);
    }
  }

  async function getDailyHours(date) {
    let reqDate;

    if (date) {
      reqDate = date;
    } else {
      reqDate = new moment().local().format("DD-MMM-YYYY");
    }
    //reqDate of format DD-MMM-YYYY;

    const baseURL =
      `${process.env.REACT_APP_FLIGHTS_API_BASEURL}/palbi?endpoint=talon/metrics/dailyhours/${reqDate}` ||
      "http://localhost:5000/api";

    try {
      const token = await getToken();
      const { data } = await axios.get(baseURL, {
        headers: { authorization: `Bearer ${token}` },
      });

      return data;
    } catch (err) {
      console.log("getDailyHours error: ", err);
    }
  }

  //Get an auth token, then setup Sockets to the AirBoss server
  useEffect(() => {
    if (!auth.currentUser) {
      return;
    }

    getAircraftInfo().then((acInfo) => {
      setAircraftInfo(acInfo);
    });

    getAirborneFlights().then((flights) => setAirborneFlights(flights));

    getDailyHours().then((hours) => {
      setDailyHours(hours);
    });

    getAcStatus().then((status) => {
      setAcStatus(status[status.length - 1]);
    });

    getMetar().then((data) => setMetar(data));

    // socket.current = io(socketEndpoint);

    // //Listen for socket "connect" events
    // socket.current.on('connect', () => {
    //     setSocketConnected(true);
    //     console.log('Server Socket Connected')
    // });

    // socket.current.on('connect_error', () => {

    //     setTimeout(() => {
    //         console.log('AirBoss Server connection Error...attempting to reconnect')
    //         socket.current = io(socketEndpoint);

    //     }, 5000);
    // })

    //Listen for socket "disconnect" events
    // socket.current.on('disconnect', () => {
    //     setSocketConnected(false);
    // });
  }, []);

  let dataUpdateInterval = 1000 * 60; //Refresh data interval every 60 seconds.
  useInterval(() => {
    getAirborneFlights().then((flights) => setAirborneFlights(flights));
    getDailyHours().then((hours) => setDailyHours(hours));
    getMetar().then((data) => setMetar(data));
    getAircraftInfo().then((acInfo) => {
      setAircraftInfo(acInfo);
    });

    // getTalonFlights().then(flights => setTalonFlights(flights))
  }, dataUpdateInterval);

  const value = {
    airborneFlights,
    dailyHours,
    getAirborneFlights,
    talonFlights,
    acStatus,
    aircraftInfo,
    metar,
  };

  return (
    <FlightContext.Provider value={value}>{children}</FlightContext.Provider>
  );
}
