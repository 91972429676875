import React, { useEffect, useState } from 'react'
import { DepartedFlightTable } from './DepartedFlightTable'
import { FlightDetails } from '../Common/FlightDetails'
import { getTalonFlights } from '../../../api/api'
import { filterFlights, sortFlights, processFlights, } from './functions/departedFlightFunctions'
// import { useLocation } from 'react-router-dom'
import { useInterval } from '../../../hooks/useInterval'
// import { useFlights } from '../../Contexts/FlightContext'

import '../Common/fid.css'


export const Departed = ({location, kiosk}) => {
    const [flights, setFlights] = useState();


    const FID_REFRESH_INTERVAL = 1000 * 30 //Set refresh interval for FID data to 30 seconds.



    //const [flights, setFlights] = useState();
    const [modalOptions, setModalOptions] = useState(
        {
            show: false,
            id: null,
            activityIDFilter: null
        }
    );

    useEffect(() => {
        //Set FID Display START / STOP Time Boundaries in Minutes Before / After NOW.
        let startOffsetMin = 720;
        let endOffsetMin = 720
        getTalonFlights()
            .then(data => processFlights(data))
            .then(data => filterFlights(data, startOffsetMin, endOffsetMin))
            .then(data => sortFlights(data))
            .then(data => setFlights(data))
            .catch(err => console.log(err))
        return () => {
            setFlights({});
        }

    }, [])

    useInterval(() => {
        let startOffsetMin = 720;
        let endOffsetMin = 720

        getTalonFlights()
            .then(data => processFlights(data))
            .then(data => filterFlights(data, startOffsetMin, endOffsetMin))
            .then(data => sortFlights(data))
            .then(data => setFlights(data))
            .catch(err => console.log(err))

        return () => {
            setFlights({});
        }

    }, FID_REFRESH_INTERVAL)


    const flightDetailsToParent = (id) => {

        let options = {
            show: true,
            id: id,
            activityIDFilter: id
        }
        setModalOptions(options)
    }

    const closeFlightDetails = () => {
        let options = {
            show: false,
            id: null,
            activityIDFilter: null
        }
        setModalOptions(options)

    }

    return (
        <>
            <div className='table-title h5'>Active Flights - {location} </div>
            <DepartedFlightTable kiosk={kiosk} flights={flights} location={location} flightDetailsToParent={flightDetailsToParent} />

            {modalOptions.show &&
                <FlightDetails kiosk={kiosk} flight={flights.filter(f => f.ACTIVITY_ID === modalOptions.id)[0]} modalOptions={modalOptions} closeFlightDetails={closeFlightDetails} />
            }
        </>


    )
}
