import React, { useRef, useState, useEffect } from 'react';
import { Alert, Image, Container, Form, Button, Card } from 'react-bootstrap';
import { useAuth } from '../Contexts/AuthContext';
import app, { auth } from '../../config/firebase'
import { Link, useHistory } from 'react-router-dom';
// import { SMSConfirmModal } from '../Auth/SMSConfirmModal'

export const UpdateProfile = () => {

    const emailRef = useRef();
    const displayNameRef = useRef();
    const passwordRef = useRef();
    const phoneRef = useRef();
    const captchaRef = useRef();
    const passwordConfirmRef = useRef();
    const {
        currentUser,
        updateEmail,
        updatePassword,
        updateDisplayName,
        // updatePhoneNumber
    } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const history = useHistory();


    useEffect(() => {
        window.recaptchaVerifier = new app.auth.RecaptchaVerifier(captchaRef.current, {
            size: 'invisible',
            callback: (response) => {
                console.info('Invisible Captcha', response);
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                //   onCaptcha();
            }
        });

    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match.');
        }

        const promises = [];
        setLoading(true);
        setError('');


        if (emailRef.current.value !== currentUser.email) {
            promises.push(updateEmail(emailRef.current.value));
        }

        if (
            passwordRef.current.value &&
            passwordRef.current.value !== currentUser.password
        ) {
            promises.push(updatePassword(passwordRef.current.value));
        }

        if (displayNameRef.current.value !== currentUser.displayName) {
            promises.push(updateDisplayName(displayNameRef.current.value));
        }

        if (phoneRef.current.value !== currentUser.phoneNumber) {

            let appVerifier = window.recaptchaVerifier;
            let provider = new app.auth.PhoneAuthProvider();
            provider.verifyPhoneNumber(phoneRef.current.value, appVerifier)
                .then(confirmationResult => {
                    //Code Sent  Prompt User to enter the code...
                    var verificationCode = window.prompt('Please enter the verification code that was sent to your mobile device.');
                    return app.auth.PhoneAuthProvider.credential(confirmationResult, verificationCode);
                })
                .then(phoneCredential => {
                    let response = auth.currentUser.updatePhoneNumber(phoneCredential)
                    
                    setSuccess(true)
                    return
                })
                .catch(error => {
                    if (error.code === 'auth/invalid-verification-code') {
                        setError('Invalid SMS Code Entered.')
                        //setLoading(false);
                        return
                    }
                    if (error.code === 'auth/credential-already-in-use') {
                        setError('This phone number is already in use.')
                        //setLoading(false);
                        return
                    }

                    if (error.code === 'auth/invalid-phone-number, Invalid format') {
                        setError('Phone Number invalid. Use Format: +12223334444')
                        //setLoading(false);
                        return
                    }
                    window.alert(`${error.code}, ${error.message}`);
                    //appVerifier.reset();

                    console.log('UpdatePhoneError', error)//Error Occurred.
                    //setLoading(false);
                })

        }



        Promise.all(promises)
            .then(() => {
                // history.push('/profile');
            })
            .catch((err) => {
                setError(`Failed to update account. ${err.message}`);
            })
            .finally(() => {
                setLoading(false);
                setSuccess(true)
            });
    };
    const handleClose = (e) => {
        e.preventDefault();
        history.push('/profile')

    }

    return (
        <>
            <div id="recaptcha-container" ref={captchaRef}></div>
            <Container
                className="mt-5 align-items-center justify-content-center"
                style={{ maxWidth: '500px' }}
            >
                <Card className="bg-dark">
                    <Card.Body>
                        <Image
                            className="pl-2 pr-5"
                            fluid
                            src="images/mfcLogo_white.png"
                        />
                        <h2 className="mb-4 text-center">Update Profile</h2>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="displayName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    ref={displayNameRef}
                                    defaultValue={currentUser.displayName}
                                />
                            </Form.Group>

                            <Form.Group id="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    ref={emailRef}
                                    defaultValue={currentUser.email}
                                />
                            </Form.Group>


                            <Form.Group id="phoneNumber">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    ref={phoneRef}
                                    defaultValue={currentUser.phoneNumber}
                                />
                            </Form.Group>

                            <Form.Group id="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    ref={passwordRef}
                                    placeholder="Leave blank to keep the same"
                                />
                            </Form.Group>
                            <Form.Group id="password-confirm">
                                <Form.Label>Verify Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    ref={passwordConfirmRef}
                                    placeholder="Leave blank to keep the same"
                                />
                            </Form.Group>
                            {/* <Form.Group className="w-10" id="is-admin">
                                <Form.Label>Admin?</Form.Label>
                                <Form.Control type="checkbox" />
                            </Form.Group> */}
                            {!success && 
                                <Button disabled={loading} className="mt-2 pt-3 pb-3 w-100" type="submit">Update</Button>
                            }
                            {success && 
                                <Button disabled={loading} className="mt-2 pt-3 pb-3 w-100" onClick={handleClose}>Close</Button>
                            }
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
            <div className="w-100 text-center mt-2">
                Change your mind?
                <Link to="/"> Cancel</Link>
            </div>

        </>
    );
};
