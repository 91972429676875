import React, { useRef, useState } from 'react';
import { Alert, Image, Container, Form, Button, Card } from 'react-bootstrap';
import { useAuth } from '../Contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';

export const Signup = () => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const { signup } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match.');
        }
        try {
            setError('');
            setLoading(true);
            await signup(emailRef.current.value, passwordRef.current.value);
            history.push('/profile');
        } catch (err) {
            setError(`Account Creation Failed. ${err.message}`);
        }
        setLoading(false);
    };

    return (
        <>
            <Container
                className="mt-5 align-items-center justify-content-center"
                style={{ maxWidth: '500px' }}
            >
                <Card className="bg-dark">
                    <Card.Body>
                        <Image
                            className="pl-2 pr-5"
                            fluid
                            src="images/mfcLogo_white.png"
                        />
                        <h2 className="mb-4 text-center">Sign Up</h2>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    ref={emailRef}
                                    required
                                />
                            </Form.Group>
                            <Form.Group id="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    ref={passwordRef}
                                    required
                                />
                            </Form.Group>
                            <Form.Group id="password-confirm">
                                <Form.Label>Verify Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    ref={passwordConfirmRef}
                                    required
                                />
                            </Form.Group>
                            <Button
                                disabled={loading}
                                className="mt-2 pt-3 pb-3 w-100"
                                type="submit"
                            >
                                Sign Up
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
            <div className="w-100 text-center mt-2">
                Already have an account?
                <Link to="login"> Log In</Link>
            </div>
        </>
    );
};
