import axios from 'axios';
import { auth } from '../config/firebase';


export const updateCador = async (data) => {
    let url = process.env.REACT_APP_AIRBOSS_URL || 'http://localhost:5000/api'
    const token = await auth.currentUser.getIdToken();

    const cadorNumber = data.cadorNumber
    data.lastModifiedBy = auth.currentUser.email


   
        return axios.patch(`${url}/cadors/${cadorNumber}`, data,
         {
            headers: { authorization: `Bearer ${token}` } 
        })
        .then((res) =>     {
            return res
        }).catch(err => {
            if (err.isAxiosError) {
                
                return err.response.data
            }
            return err
        }); 
    

}

export const getCadors = async (cadorNumber) => {
    let url = process.env.REACT_APP_AIRBOSS_URL || 'http://localhost:5000/api'
    const token = await auth.currentUser.getIdToken();

    if (cadorNumber) {
        return axios.get(`${url}/cadors/${cadorNumber}`, {
            headers: { authorization: `Bearer ${token}` },
        })
        .then((res) =>     {
            return res
        }).catch(err => {
            if (err.isAxiosError) {
                
                return err.response.data
            }
            return err
        }); 
    }
    return 
}

export const getCadorsforTable = async (num) => {
    let url = process.env.REACT_APP_AIRBOSS_URL || 'http://localhost:5000/api'
    const token = await auth.currentUser.getIdToken();

    
        return axios.get(`${url}/cadors/?retrieve=${num}`, {
            headers: { authorization: `Bearer ${token}` },
        })
        .then((res) =>     {
            return res
        }).catch(err => {
            if (err.isAxiosError) {
                
                return err.response.data
            }
            return err
        }); 
    
    
}


export const createCador = async (data) => {
    let url = process.env.REACT_APP_AIRBOSS_URL || 'http://localhost:5000/api'
    const token = await auth.currentUser.getIdToken();
    data.createdBy = auth.currentUser.email
    return axios.post(`${url}/cadors`, 
    data,
    {
        headers: { 
            authorization: `Bearer ${token}`,
     },
    })
    .then((res) => res).catch(err => {
        console.log('Error with CADOR Creation:', err)
        return err
        
    });

}
export const deleteCador = async (cadorNumber) => {

    let url = process.env.REACT_APP_AIRBOSS_URL || 'http://localhost:5000/api'
    const token = await auth.currentUser.getIdToken();
   
        return axios.delete(`${url}/cadors/${cadorNumber}`,
         {
            headers: { authorization: `Bearer ${token}` } 
        })
        .then((res) =>     {
            return res
        }).catch(err => {
            if (err.isAxiosError) {
                
                return err.response.data
            }
            return err
        }); 

}