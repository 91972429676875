export const navErrors = [
    'Arrival Heading Error',
    'Arrival Altitude Error',
    'Departure Heading Error',
    'Departure Altitude Error',
    'Circuit Altitude Error',
    'Circuit Heading Error',
    'Circuit Joining Error',
    'Altitude Error',
    'Heading Error',
    'Runway Error',
    'Airspace Error'
]

export const initialFormData = {
    cadorNumber: '',
    occurrenceCategory: '',
    TALON_ACT_ID: '',
    occurrenceType: 'Incident',
    occurrenceDate: '',
    occurrenceTime: '',
    dayOrNight: 'Not Specified',
    aerodromeID: '',
    occurrenceLocation: '',
    reportedBy: '',
    tsbClassOfInvestigation: 'Not Specified',
    fatalities: 0,
    injuries: 0,
    occurrenceEventInfo: 'Not Specified',
    aircraftRegistration: '',
    aircraftModel: '',
    phaseOfFlight: 'Parked',
    flightRules: 'VFR',
    damage: 'No Damage',
    aircraftEventInfo: 'Not Specified',
    dateEntered: '',
    narrative: ''
}

export const occurrenceEventInformation = [
    'Not Specified',
    'Aerodrome - other',
    'ATM - operations',
    'Aerodrome - visual aids',
    'Aerodrome - power failure',
    'ATM - other',
    'Conflict - near collision  (VFR or IFR)',
    'Alleged Canadian Aviation Regulations (CARs) infraction',
    'Incursion - runway - animal',
    'Blown tire/wheel failure',
    'Aerodrome, runway or taxiway shutdown',
    'FOD (foreign object debris)',
    'Conflict - potential',
    'Incursion - runway - aircraft',
    'ATS operating irregularity',
    'Other operational incident',
    'Conflict - loss of separation',
    'ELT',
    'ATM - weather observation systems',
    'Aerodrome - operations',
    'ATM - NAVAIDS/radar',
    'Overshoot/missed approach',
    'Incursion - runway - vehicle',
    'Weather - precipitation',
    'ATM - ILS irregularity',
    'Communication navigation surveillance/air traffic system',
    'Aircraft navigation/communication equipment',
    'Communication error',
    'Controlled airspace - unauthorized entry',
    'ATM - inaccurate aeronautical information',
    'Rejected take-off',
    'Aerodrome - runway or taxiway surface condition',
    'Navigation error',
    'TCAS alert',
    'Weather balloon, meteor, rocket, CIRVIS/UFO',
    'Incursion - runway - pedestrian',
    'Bird strike',
    'Conflict - IFR & VFR',
    'Diversion',
    'Precautionary landing',
    'Part or pieces separate from an aircraft',
    'Public complaint',
    'Loss of control - on ground',
    'Collision on ground',
    'Wake turbulence/vortices',
    'Electrical problem',
    'Fuel - leak',
    'Landing gear',
    'Runway excursion',
    'Aerodrome - foreign authorities involved',
    'Security acts',
    'Weather - clear air turbulence (CAT)/turbulence',
    'Weather - lightning',
    'Weather - icing',
    'Fuel - dumping',
    'Fuel - spill',
    'Dangerous goods/hazardous materials',
    'Engine failure',
    'Weather - other',
    'Weather - visibility',
    'Class F airspace violation',
    'Collision with object',
    'Navigation assistance',
    'Engine - malfunction',
    'Brakes - overheated',
    'Engine oil problem',
    'Forced landing',
    'Declared emergency/priority',
    'Collision with terrain',
    'Fuel - starvation',
    'Brakes - other',
    'Aerodrome property - death/injury',
    'Disruptive passenger',
    'Door/canopy openings indications',
    'Brakes - frozen',
    'Propeller/rotor strike',
    'Aerodrome - labour action',
    'Incursion - manoeuvring area',
    'Parachute-related event',
    'Loss of control - inflight',
    'Natural disaster (environment)',
    'Hydraulic problem',
    'Windshield/window (aircraft)',
    'Smoke/fumes - aircraft',
    'Brakes - failure',
    'Decompression/pressurization',
    'Missing aircraft',
    'Fire/major damage - facilities',
    'Flight instrument failure',
    'Fuel - contamination',
    'Overturn',
    'Wire strike',
    'Fire/smoke (warning)',
    'Hard landing',
    'Flight control systems (ailerons, rudder, rotors, flaps, main, tail)',
    'Brakes',
    'Collision midair',
    'Engine shut down',
    'Fuel management',
    'Fire - engine',
    'Fuel - exhaustion',
    'Airframe failure',
    'Aircraft incident - minor damage',
    'False warning - smoke or fire',
    'Roll over',
    'Evacuation (aircraft)',
    'Nose over',
    'Flight plan â€“ activation',
    'Ground handling services',
    'Collision on ground with aircraft',
    'SAR/comm search',
    'Parked aircraft damage',
    'Bird presence',
    'Aerodrome noise',
    'Weather - wind',
    'Laser interference',
    'Flight plan â€“ information',
    'Crew incapacitation',
    'Flight plan â€“ route',
    'Airspeed Limitations - Deviation from CARs',
    'Animal strike (or risk of collision with animal)',
    'Weather - wind shear',
    'Object dropped from aircraft',
    'Landing gear - incorrect configuration',
    'Medical emergency',
    'Mechanical/technical malfunction of aircraft - other',
    'Blue ice',
    'Landing in proximity of the intended surface',

]

export const aircraftEventInfo = [
    'Accident - Crash',
    'Aerodrome - other',
    'Aerodrome - power failure',
    'Aerodrome - runway or taxiway surface condition',
    'Aerodrome - visual aids',
    'Aerodrome noise',
    'Aerodrome property - death/injury',
    'Aerodrome, runway or taxiway shutdown',
    'Aircraft incident - conflict - unsafe operation',
    'Aircraft incident - fuel - other',
    'Aircraft incident - minor damage',
    'Aircraft navigation/communication equipment',
    'Airframe failure',
    'Airspeed Limitations - Deviation from CARs',
    'Alleged Canadian Aviation Regulations (CARs) infraction',
    'Animal strike (or risk of collision with animal)',
    'ATM - ILS irregularity',
    'ATM - inaccurate aeronautical information',
    'ATM - NAVAIDS/radar',
    'ATM - operations',
    'ATM - other',
    'ATM - weather observation systems',
    'ATS operating irregularity',
    'Bird presence',
    'Bird strike',
    'Blown tire/wheel failure',
    'Blue ice',
    'Brakes',
    'Brakes - failure',
    'Brakes - frozen',
    'Brakes - other',
    'Brakes - overheated',
    'Class F airspace violation',
    'Collision midair',
    'Collision on ground',
    'Collision on ground with aircraft',
    'Collision on ground with person',
    'Collision with object',
    'Collision with terrain',
    'Communication error',
    'Communication navigation surveillance/air traffic system',
    'Conflict - IFR & VFR',
    'Conflict - loss of separation',
    'Conflict - near collision  (VFR or IFR)',
    'Conflict - potential',
    'Controlled airspace - unauthorized entry',
    'Crew incapacitation',
    'Dangerous cargo problems (on board)',
    'Dangerous goods/hazardous materials',
    'Declared emergency/priority',
    'Decompression/pressurization',
    'Disruptive passenger',
    'Diversion',
    'Door/canopy openings indications',
    'Electrical problem',
    'ELT',
    'Engine - malfunction',
    'Engine failure',
    'Engine oil problem',
    'Engine shut down',
    'Evacuation (aircraft)',
    'External load',
    'False indication warning',
    'False warning - smoke or fire',
    'Fire - aircraft (cockpit, cargo or passenger area)',
    'Fire - engine',
    'Fire/smoke (warning)',
    'Flight control systems (ailerons, rudder, rotors, flaps, main, tail)',
    'Flight instrument failure',
    'Flight plan â€“ information',
    'Flight plan â€“ route',
    'Flight plan activation',
    'FOD (foreign object debris)',
    'Forced landing',
    'Fuel - contamination',
    'Fuel - dumping',
    'Fuel - exhaustion',
    'Fuel - incorrect fuel',
    'Fuel - leak',
    'Fuel - low/declared minimum',
    'Fuel - other',
    'Fuel - spill',
    'Fuel - starvation',
    'Fuel management',
    'GPWS/TAWS alert',
    'Ground handling services',
    'Hard landing',
    'Hydraulic problem',
    'IFR operations below minimum',
    'Incursion - manoeuvring area',
    'Incursion - runway - aircraft',
    'Incursion - runway - animal',
    'Incursion - runway - pedestrian',
    'Incursion - runway - vehicle',
    'Landing gear',
    'Landing gear - incorrect configuration',
    'Landing in proximity of the intended surface',
    'Laser interference',
    'Loss of control - inflight',
    'Loss of control - on ground',
    'Mechanical/technical malfunction of aircraft - other',
    'Medical emergency',
    'Missing aircraft',
    'Natural disaster (environment)',
    'Navigation assistance',
    'Navigation error',
    'Nose over',
    'Object dropped from aircraft',
    'Other operational incident',
    'Overshoot/missed approach',
    'Overturn',
    'Parachute-related event',
    'Parked aircraft damage',
    'Part or pieces separate from an aircraft',
    'Precautionary landing',
    'Propeller/rotor strike',
    'Public complaint',
    'Regulatory - other infraction',
    'Regulatory - weather infraction',
    'Rejected take-off',
    'Roll over',
    'Runway excursion',
    'SAR/comm search',
    'Security acts',
    'Smoke/fumes - aircraft',
    'Tail strike',
    'Take-off without clearance',
    'TCAS alert',
    'Transmission problem',
    'Wake turbulence/vortices',
    'Weather - clear air turbulence (CAT)/turbulence',
    'Weather - icing',
    'Weather - lightning',
    'Weather - other',
    'Weather - precipitation',
    'Weather - visibility',
    'Weather - wind',
    'Weather - wind shear',
    'Weather balloon, meteor, rocket, CIRVIS/UFO',
    'Windshield/window (aircraft)',
    'Wing strike',
    'Wire strike',
    'Not Specified'


]

export const damage = [
    'No Damage',
    'Minor',
    'Substantial',
    'Destroyed',
    'Unknown'
]

export const phaseOfFlight = [
    'Parked',
    'Taxi',
    'Takeoff',
    'Climb',
    'Cruise',
    'Descent',
    'Approach',
    'Landing',
    'Roll Out',
    'Hover',
    'Unknown',
]

export const tsbClassOfInvestigation = [
    'Class 1',
    'Class 2',
    'Class 3',
    'Class 4',
    'Class B-2',
    'Class C-3',
    'Not Specified',
]

export const cadorOccurenceCategory = [
    'Abnormal runway contact',
    'Abrupt manoeuvre',
    'Aerodrome',
    'Airprox/TCAS alert/loss of separation/(near) midair collisions',
    'ATM/CNS',
    'Bird',
    'Cabin safety events',
    'Collision with obstacle(s) during take-off or landing whilst airborne.',
    'Controlled flight into or toward terrain',
    'Evacuation',
    'External load related occurrences',
    'Fire/smoke (non-impact)',
    'Fire/smoke (post-impact)',
    'Fuel related',
    'Glider towing related events',
    'Ground collision',
    'Ground handling',
    'Icing',
    'Loss of control - ground',
    'Loss of control - inflight',
    'Low altitude operations',
    'Medical',
    'Navigation Errors',
    'Other',
    'Runway excursion',
    'Runway Incursion',
    'Runway incursion - animal',
    'Runway incursion - vehicle, aircraft or person',
    'Security related',
    'System/component failure or malfunction [non-powerplant]',
    'System/component failure or malfunction [powerplant]',
    'Turbulence encounter',
    'Undershoot/overshoot',
    'Unintended flight in IMC',
    'Unknown or undetermined',
    'Wildlife',
    'Wind shear or thunderstorm',
    'Not Specified'
]