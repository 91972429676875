import React, { useState, useEffect, Fragment } from "react";
import { Card, Table } from "react-bootstrap";
import moment from "moment-timezone";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCloud,
  faEye,
  faWind,
  faThermometerHalf,
  faCloudMoon,
  faCloudSun,
} from "@fortawesome/free-solid-svg-icons";

const visibility = (metar) => {
  let distance = metar.visibility_statute_mi;
  let precipType = metar.wx_string;

  return { distance, precipType };
};

const cloudCover = (metar) => {
  let cloudLayers = [];
  if (metar.sky_condition_0) {
    let layerHeight = metar.sky_condition_0.cloud_base_ft_agl;
    let layerType = metar.sky_condition_0.sky_cover;
    cloudLayers.push({
      layerType,
      layerHeight,
    });
  }
  if (metar.sky_condition_1) {
    let layerHeight = metar.sky_condition_1.cloud_base_ft_agl;
    let layerType = metar.sky_condition_1.sky_cover;
    cloudLayers.push({
      layerType,
      layerHeight,
    });
  }
  if (metar.sky_condition_2) {
    let layerHeight = metar.sky_condition_2.cloud_base_ft_agl;
    let layerType = metar.sky_condition_2.sky_cover;
    cloudLayers.push({
      layerType,
      layerHeight,
    });
  }

  return cloudLayers;
};

const flightCategory = (metar) => {
  if (metar.flight_category === "VFR")
    return <div className="badge badge-vfr">VFR</div>;
  if (metar.flight_category === "MVFR")
    return <div className="badge badge-mvfr">MVFR</div>;
  if (metar.flight_category === "IFR")
    return <div className="badge badge-ifr">IFR</div>;
  if (metar.flight_category === "LIFR")
    return <div className="badge badge-lifr">LIFR</div>;
};

const sunSetRise = async (base) => {
  let currentDateTime = new moment();
  let currentDate = new moment().format("YYYY-MM-DD");

  //Between Sunrise and Sunset or
  // Before Sunrise and Sunset or
  // After Sunrise and Sunset
  const makeUrl = (l, date) => {
    if (l.toUpperCase() === "CYQM") {
      let lat = 46.090946;
      let long = -64.790497;
      const url = `https://api.sunrise-sunset.org/json?lat=${lat}&lng=${long}&date=${date}&callback=&formatted=0`;
      return url;
    } else if (l.toUpperCase() === "CYFC") {
      let lat = 45.964993;
      let long = -66.646332;
      const url = `https://api.sunrise-sunset.org/json?lat=${lat}&lng=${long}&date=${date}&callback=&formatted=0`;
      return url;
    } else if (l.toUpperCase() === "CYRT") {
      let lat = 53.318889;
      let long = -60.426252;
      const url = `https://api.sunrise-sunset.org/json?lat=${lat}&lng=${long}&date=${date}&callback=&formatted=0`;
      return url;
    } else if (l.toUpperCase() === "CYTH") {
      let lat = 55.804722;
      let long = -97.8625;
      const url = `https://api.sunrise-sunset.org/json?lat=${lat}&lng=${long}&date=${date}&callback=&formatted=0`;
      return url;
    }
  };

  let url = makeUrl(base, currentDate);
  let { data } = await axios.get(url);
  let sunset = new moment(data.results.sunset);
  let sunrise = new moment(data.results.sunrise);

  if (currentDateTime.isBetween(sunrise, sunset, [])) {
    let hourDiff = currentDateTime.diff(sunset, "hours");
    let minuteDiff = currentDateTime.diff(sunset, "minutes");
    return {
      next: "sunset",
      time: sunset.format("HH:mm"),
    };
  }

  if (currentDateTime.isBefore(sunrise) && currentDateTime.isBefore(sunset)) {
    return {
      next: "sunrise",
      time: sunrise.format("HH:mm"),
    };
  }

  if (currentDateTime.isAfter(sunrise) && currentDateTime.isAfter(sunset)) {
    let newDate = currentDateTime.add(1, "d").format();
    url = makeUrl(base, newDate);
    let { data } = await axios.get(url);
    let newSunrise = moment(data.results.sunrise).format("HH:mm");

    return {
      next: "sunrise",
      time: newSunrise,
    };
  }
};
const preferredRunway = (airport, windSpeed, windDirection) => {};

const wxMinima = (base, missionType, metar) => {
  //1. Check Ceiling and VIS to determine highest level of authorization.
  //2. Determine preferred runway
  //3. Calculate x-wind component and h-wind component
  //4. Determine highest level of authoriation by the most restrictive of X-Wind, H-Wind, Total Wind
};

export const WeatherStatus = ({ base, weather, kiosk }) => {
  const [sunriseSunset, setSunriseSunset] = useState();

  useEffect(() => {
    sunSetRise(base).then((response) => setSunriseSunset(response));
  }, []);

  return (
    <div>
      <Card bg="dark" className="shadow">
        {!weather && (
          <>
            <Card.Header>
              <Card.Title>
                <div>{base}</div>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Spinner animation="border" role="status"></Spinner>
            </Card.Body>
          </>
        )}
        {weather && (
          <>
            <Card.Header>
              <Card.Title>
                <div>
                  {base} {flightCategory(weather[base][0])}
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Table
                borderless
                size="sm"
                resonsive="xl"
                className="text-center"
                id="flights"
              >
                <tbody className="h6 text-light bg-dark>">
                  <tr className="">
                    <td className="align-middle">
                      <FontAwesomeIcon icon={faCloud} size="lg" />
                    </td>
                    <td>
                      {cloudCover(weather[base][0]).map((layer, index) => {
                        return (
                          <Fragment key={index}>
                            {layer.layerType === "CLR" ? (
                              <>CLR</>
                            ) : (
                              <>
                                {" "}
                                {layer.layerType}@{layer.layerHeight}
                              </>
                            )}
                          </Fragment>
                        );
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faEye} size="lg" />
                    </td>
                    <td className="">
                      {visibility(weather[base][0]).distance}SM
                      {visibility(weather[base][0])
                        ? visibility(weather[base][0]).precipType
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faWind} size="lg" />
                    </td>
                    <td>
                      <div>
                        {weather[base][0].wind_dir_degrees} {"\u00b0"} @{" "}
                        {weather[base][0].wind_speed_kt}{" "}
                        {weather[base][0].wind_gust_kt ? (
                          <span>G {weather[base][0].wind_gust_kt}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FontAwesomeIcon icon={faThermometerHalf} size="lg" />
                    </td>
                    <td>
                      <div>
                        {weather[base][0].temp_c} {"\u00b0"}C
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {sunriseSunset && (
                        <>
                          {sunriseSunset.next === "sunrise" ? (
                            <FontAwesomeIcon icon={faCloudSun} size="lg" />
                          ) : (
                            <FontAwesomeIcon icon={faCloudMoon} size="lg" />
                          )}
                        </>
                      )}
                    </td>
                    <td>
                      {sunriseSunset && sunriseSunset.time.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {!kiosk && <div className="h6">{weather[base][0].raw_text}</div>}
            </Card.Body>
            <Card.Footer>
              <div className="h6 text-muted">
                Last Updated:{" "}
                {moment(weather[base][0].observation_time).fromNow()}
              </div>
            </Card.Footer>
          </>
        )}
      </Card>
    </div>
  );
};
